import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialAnalyticsState = {
  loading: false,
  error: null,

  totalTimeError: null,
  totalTimeLoading: false,

  averageTimeError: null,
  averageTimeLoading: false,

  analytics: {},
  activeAnalyticsId: null,
};

export const useAnalyticsStore = create(
  persist(
    (set) => ({
      ...initialAnalyticsState,
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),

      setTotalTimeError: (totalTimeError) => set({ totalTimeError }),
      setTotalTimeLoading: (totalTimeLoading) => set({ totalTimeLoading }),

      setAverageTimeError: (averageTimeError) => set({ averageTimeError }),
      setAverageTimeLoading: (averageTimeLoading) =>
        set({ averageTimeLoading }),

      setAnalytics: (analytics) => set({ analytics }),
      setActiveAnalyticsId: (activeAnalyticsId) => set({ activeAnalyticsId }),
      reset: () => set(initialAnalyticsState),
    }),
    { name: "analytics-storage" }
  )
);
