import { ChevronDown } from "lucide-react";
import { FiCheck } from "react-icons/fi";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { slideInVariants } from "@/utils/framerAnimationConfigs";
import { useClickOutside } from "@/hooks/hooks";

const DropdownContext = createContext();

export default function Dropdown({
  children,
  selectedItem,
  setSelectedItemAction,
  items,
  label,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedItemState, setSelectedItemState] = useState(selectedItem);
  const [searchQuery, setSearchQuery] = useState("");

  const selectDropdownRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useClickOutside(dropdownRef, () => {
    setOpenDropdown(false);
    setSearchQuery("");
  });

  const toggleOpenDropdown = () => {
    setOpenDropdown((prev) => {
      if (!prev) {
        // Focus the input field when opening
        setTimeout(() => inputRef.current?.focus(), 0); // Delay focusing to ensure dropdown is rendered
      }
      return !prev;
    });
  };

  const filteredItems = useMemo(() => {
    return items.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [items, searchQuery]);

  const contextValue = useMemo(() => {
    return {
      openDropdown,
      selectedItem: selectedItemState,
      setSelectedItem: setSelectedItemState,
      toggleOpenDropdown,
      selectDropdownRef,
      setSelectedItemAction,
      items: filteredItems,
      setOpenDropdown,
      label,
      searchQuery,
      setSearchQuery,
      inputRef,
    };
  }, [
    openDropdown,
    selectedItemState,
    setSelectedItemAction,
    filteredItems,
    setOpenDropdown,
    label,
    searchQuery,
  ]);

  return (
    <DropdownContext.Provider value={contextValue}>
      <div className="relative w-full" ref={dropdownRef}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

const Header = ({ className }) => {
  const { label } = useContext(DropdownContext);

  return <h3 className={`mb-2 text-sm text-me ${className}`}>{label}</h3>;
};

const Button = () => {
  const { selectedItem, toggleOpenDropdown, selectDropdownRef, label } =
    useContext(DropdownContext);

  return (
    <button
      className="px-4  py-2 flex items-center justify-between w-full rounded border border-[#828FA340] hover:border-primary cursor-pointer relative"
      onClick={() => toggleOpenDropdown()}
      ref={selectDropdownRef}
      type="button">
      <div className="text-[13px] font-medium capitalize space-x-2">
        {label === "Location" && (
          <span className={`flag-icon flag-icon-${selectedItem?.id}`}></span>
        )}
        <span>{selectedItem?.name}</span>
      </div>
      <span className="block">
        <ChevronDown color="#635FC7" size={24} />
      </span>
    </button>
  );
};

const List = ({ direction = "top", className, searchClass }) => {
  const { openDropdown, items, setSearchQuery, inputRef } =
    useContext(DropdownContext);

  return (
    <AnimatePresence>
      {openDropdown && (
        <motion.div
          initial={slideInVariants.initial}
          animate={slideInVariants.animate}
          exit={slideInVariants.exit}
          key={openDropdown}
          className={`absolute border border-lines rounded-md z-10 left-0 grid w-full gap-1 pt-0 py-3 px-1 bg-veryDarkGray text-mediumGray ${
            direction === "top" ? "-top-[200px]" : " -bottom-[220px]"
          } ${className} max-h-[200px] overflow-y-scroll`}>
          <div className={`sticky z-20 top-0 px-2 pt-3 mb-4 ${searchClass}`}>
            <input
              type="text"
              placeholder="Search"
              className=" input__style hover:border-primary"
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={inputRef}
            />
          </div>
          <motion.ul className={`grid gap-1`}>
            {items.map((item) => (
              <Dropdown.Item key={item.id} item={item} direction={direction} />
            ))}
          </motion.ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Item = ({ item, direction }) => {
  const {
    selectedItem,
    setSelectedItem,
    toggleOpenDropdown,
    setSelectedItemAction,
    label,
    searchQuery, // Get the search query
    setSearchQuery,
  } = useContext(DropdownContext);

  // Function to highlight the search query in red
  const highlightText = (text, query) => {
    if (!query) return text; // If no query, return the full text

    const regex = new RegExp(`(${query})`, "gi"); // Create a regex to match the query (case-insensitive)
    const parts = text.split(regex); // Split the text into parts based on the query

    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ color: "white" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <button
      className={`text-[13px]  font-medium cursor-pointer flex items-center gap-2  p-2 rounded-md ${
        direction === "top" ? "hover:bg-darkGray" : " hover:bg-veryDarkGray"
      } ${item.id === selectedItem?.id ? "text-textColor" : ""}`}
      onClick={() => {
        setSelectedItem(item);
        setSelectedItemAction?.(item.id !== "" ? item : selectedItem);
        toggleOpenDropdown();
        setSearchQuery("");
      }}
      type="button">
      <FiCheck
        color={item.id === selectedItem?.id ? "#635fc7" : "transparent"}
        size={16}
      />
      {label === "Location" && (
        <span className={`flag-icon flag-icon-${item?.id}`}></span>
      )}
      <span>{highlightText(item.name, searchQuery)}</span>{" "}
      {/* Highlight text */}
    </button>
  );
};

// const Item = ({ item, direction }) => {
//   const {
//     selectedItem,
//     setSelectedItem,
//     toggleOpenDropdown,
//     setSelectedItemAction,
//     label,
//   } = useContext(DropdownContext);

//   return (
//     <button
//       className={`text-[13px] capitalize font-medium cursor-pointer flex items-center gap-2  p-2 rounded-md ${
//         direction === "top" ? "hover:bg-darkGray" : " hover:bg-veryDarkGray"
//       } ${item.id === selectedItem?.id ? "text-textColor" : ""}`}
//       onClick={() => {
//         setSelectedItem(item);
//         setSelectedItemAction?.(item.id !== "" ? item : selectedItem);
//         toggleOpenDropdown();
//       }}
//       type="button">
//       <FiCheck
//         color={item.id === selectedItem?.id ? "#635fc7" : "transparent"}
//         size={16}
//       />
//       {label === "Location" && (
//         <span className={`flag-icon flag-icon-${item?.id}`}></span>
//       )}
//       <span>{item.name}</span>
//     </button>
//   );
// };

Dropdown.Header = Header;
Dropdown.Button = Button;
Dropdown.List = List;
Dropdown.Item = Item;

Dropdown.propTypes = {
  children: PropTypes.node,
  setSelectedItemAction: PropTypes.func,
  selectedItem: PropTypes.any,
  items: PropTypes.array,
  label: PropTypes.string,
};

Header.propTypes = {
  className: PropTypes.string,
};
List.propTypes = {
  direction: PropTypes.string,
  className: PropTypes.string,
  searchClass: PropTypes.string,
};

Item.propTypes = {
  item: PropTypes.object,
  direction: PropTypes.string,
};
