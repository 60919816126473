// Static Imports

import KreedLogo from "/kreed-logo.svg";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link
      to="home"
      className={`items-center hidden border-lines gap-4 logo pr-7 sm:flex `}>
      <img
        className="w-6 lg:w-8 aspect-square"
        src={KreedLogo}
        alt="Kreed Logo"
      />

      <span className="hidden text-2xl font-bold lg:text-3xl text-textColor sm:block">
        Kreed
      </span>
    </Link>
  );
}
