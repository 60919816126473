// Component imports
import HeaderKebabDropdown from "@/features/header/HeaderKebabDropdown";
import Avatar from "@/UI/Avatar";

// Store Imports

// External Libraries
import { FaBell } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

// Static imports
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "../notifications/notificationsStore";
import { useProfileStore } from "../user/profile/profileStore";

export default function HeaderHomeDropdown() {
  // Zustand States
  const { userData } = useProfileStore((store) => store);

  const { profilePicture } = userData || {};

  const announcementNotifications = useAnnouncementNotificationsStore(
    (store) => store.announcementNotifications
  );

  const taskNotifications = useTaskNotificationsStore(
    (store) => store.taskNotifications
  );

  // Hooks
  const location = useLocation();

  const unreadMentions = announcementNotifications?.filter((a) => !a.hasRead);

  const unreadTasks = taskNotifications?.filter((a) => !a.hasRead);

  return (
    <>
      {/* Profile Avatar */}
      <Link to="/profile" aria-label="Profile">
        <Avatar
          width="2.5"
          imgSrc={profilePicture}
          className={"max-[1200px]:hidden"}
        />
      </Link>

      {/* Notifications Icon */}
      <Link to="/notifications" aria-label="Notifications">
        <div className="max-[1200px]:hidden relative  p-1.5 rounded-full hover:bg-veryDarkGray">
          <FaBell
            size={25}
            fill={location.pathname === "/notifications" ? "yellow" : "white"}
          />

          <span
            className={`absolute right-0 flex items-center justify-center py-1 px-2 text-xs translate-x-1/2 rounded-full -top-[10%] ${
              unreadMentions?.length + unreadTasks?.length > 0
                ? "bg-[#EA5555]"
                : " bg-mediumGray"
            }`}>
            {unreadMentions?.length + unreadTasks?.length || 0}
          </span>
        </div>
      </Link>

      {/* Header Kebab Dropdown */}
      <HeaderKebabDropdown />
    </>
  );
}
