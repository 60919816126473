import { useState } from "react";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

// Store Imports
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { useColumnStore } from "@/features/column/columnStore";
import { useDomainStore } from "@/features/domain/domainStore";
import { useOnboardingStore } from "@/features/onboarding/onBoardingStore";
import { usePanelStore } from "@/features/panel/panelStore";
import { useProfileStore } from "@/features/user/profile/profileStore";
import { useAnalyticsStore } from "@/features/analytics/analyticsStore";
import { useNavigate } from "react-router-dom";
import { initializeSocket } from "@/sockets/socket";

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Zustand States
  const { setDomains, setActiveDomain } = useDomainStore((state) => state);

  const setUserData = useProfileStore((state) => state.setUserData);

  const setPanels = usePanelStore((store) => store.setPanels);

  const setColumns = useColumnStore((store) => store.setColumns);

  const setCollaborators = useCollaboratorsStore(
    (store) => store.setCollaborators
  );

  const setIsOnboarding = useOnboardingStore((store) => store.setIsOnboarding);

  const { setAnalytics, setActiveAnalyticsId } = useAnalyticsStore(
    (store) => store
  );

  const navigate = useNavigate();

  const handleSignup = async (userData) => {
    setLoading(true);
    setErrorMessage(null);

    const config = {
      method: "post",
      url: "/auth/signup",
      headers: { "Content-Type": "application/json" },
      data: userData,
    };

    const { data, error } = await apiRequestWithTimeout(config);

    if (error) {
      const errorMessage = handleApiError(error);
      setErrorMessage(errorMessage.message);
      setLoading(false);
      return;
    }

    // Call handleSuccess if no error
    handleSuccess(data);
    setLoading(false);
  };

  const handleSuccess = (data) => {
    const { user_data, domains, access_token } = data;
    const firstDomain = domains?.domains?.[0];

    const params = { userId: user_data.id, domainId: firstDomain.id };

    initializeSocket(params);

    // Store the access token
    localStorage.setItem("kreed_at", access_token);

    // Set states with extracted data
    setUserData(user_data);
    setDomains(domains?.domains);
    setActiveDomain(firstDomain);
    setPanels(domains?.panels);
    setColumns(firstDomain?.status);
    setCollaborators(domains?.members);
    setAnalytics(domains?.analytics.domainId, domains?.analytics);
    setActiveAnalyticsId(domains?.analytics.domainId);

    // Navigate to the home page
    navigate("/home");
    setIsOnboarding(true);
  };

  return {
    handleSignup,
    loading,
    setLoading,
    errorMessage,
  };
};
