// External Libraries
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const variants = {
  initial: {
    scaleY: 0.5,
    opacity: 0,
  },
  animate: {
    scaleY: 1,
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: "mirror",
      duration: 1,
      ease: "circIn",
    },
  },
};

export default function BarLoader({
  width = 1,
  height = 6,
  color = "bg-white",
}) {
  return (
    <motion.div
      transition={{
        staggerChildren: 0.25,
      }}
      initial="initial"
      animate="animate"
      className="flex justify-center gap-1 mx-auto">
      <motion.div
        variants={variants}
        className={`w-${width} h-${height} ${color} rounded-[1px]`}
      />
      <motion.div
        variants={variants}
        className={`w-${width} h-${height} ${color} rounded-[1px]`}
      />
      <motion.div
        variants={variants}
        className={`w-${width} h-${height} ${color} rounded-[1px]`}
      />
      <motion.div
        variants={variants}
        className={`w-${width} h-${height} ${color} rounded-[1px]`}
      />
      <motion.div
        variants={variants}
        className={`w-${width} h-${height} ${color} rounded-[1px]`}
      />
    </motion.div>
  );
}

BarLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
