import { useEffect, useState } from "react";

const storedLanguage = localStorage.getItem("language");

const parsedLang = JSON.parse(storedLanguage) || "en";

export function formatDate(dateString, isMobile = false) {
  const date = new Date(dateString);

  const options = {
    ...(isMobile ? {} : { weekday: "long" }),
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  try {
    return date.toLocaleDateString(parsedLang, options);
  } catch (error) {
    console.error("Error formatting date:", error);
    return date.toLocaleDateString("en", options); // Fallback to "en" in case of an error
  }
}

export function convertToISODateTime(time) {
  const currentDate = new Date();
  let hours = parseInt(time.hours, 10);
  const minutes = parseInt(time.minutes, 10);

  // Convert 12-hour format to 24-hour format
  if (time.period === "PM" && hours !== 12) {
    hours += 12;
  } else if (time.period === "AM" && hours === 12) {
    hours = 0;
  }

  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  return currentDate.toISOString();
}

export function useFormattedLocalTime() {
  const [time, setTime] = useState(getFormattedLocalTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getFormattedLocalTime());
    }, 60000); // Set interval to 60000 milliseconds (1 minute) instead of 1000 milliseconds (1 second)

    // Clear interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return time;
}

export function getFormattedLocalTime() {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const isPM = hours >= 12;
  const formattedHours = ((hours + 11) % 12) + 1; // Convert 24h to 12h format
  const formattedTime = `${formattedHours}:${minutes} ${isPM ? "PM" : "AM"}`;

  const timezoneOffset = -now.getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
    .toString()
    .padStart(2, "0");
  const offsetMinutes = (Math.abs(timezoneOffset) % 60)
    .toString()
    .padStart(2, "0");
  const timezone = `GMT ${
    timezoneOffset >= 0 ? "+" : "-"
  }${offsetHours}:${offsetMinutes}`;

  return {
    formattedTime,
    timezone,
  };
}

export function getDayString(date) {
  const inputDate = new Date(date);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate - inputDate;

  // Calculate the difference in days
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  // Define the time intervals
  const intervals = [
    { label: "today", threshold: 0 },
    { label: "yesterday", threshold: 1 },
    { label: "2 days ago", threshold: 2 },
    { label: "3 days ago", threshold: 3 },
    { label: "4 days ago", threshold: 4 },
    { label: "5 days ago", threshold: 5 },
    { label: "6 days ago", threshold: 6 },
    { label: "1 week ago", threshold: 7 },
  ];

  // Find the appropriate label based on the difference in days
  for (const interval of intervals) {
    if (daysDifference <= interval.threshold) {
      return interval.label;
    }
  }

  // If the date is more than 1 week ago, return a default string

  const options = { year: "numeric", month: "short", day: "numeric" };
  return inputDate.toLocaleDateString("en-US", options);
}

export const groupByDate = (announcements) => {
  return announcements?.reduce((acc, announcement) => {
    // Use the formatted date as the key
    const dateKey = formatDate(announcement.sentAt);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(announcement);
    return acc;
  }, {});
};

export function convertToReadableTime(isoString) {
  const date = new Date(isoString);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedHours = hours.toString().padStart(2, "0");
  return `${formattedHours}:${minutes} ${period}`;
}

export function convertFromISODateTime(isoString) {
  const date = new Date(isoString);

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedHours = hours.toString().padStart(2, "0");

  return {
    hours: formattedHours,
    minutes: minutes,
    period: period,
  };
}

export function formatTimeToLocalTimeString(dateString) {
  const storedLanguage = localStorage.getItem("language");

  const parsedLang = JSON.parse(storedLanguage) || "en";

  const date = new Date(dateString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  try {
    return date.toLocaleTimeString(parsedLang, options);
  } catch (error) {
    console.error("Error formatting time:", error);
    return date.toLocaleTimeString("en", options); // Fallback to "en" in case of an error
  }
}

export function formatCreatedDate(dateString) {
  const now = new Date();
  const date = new Date(dateString);

  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) {
    // More than a week ago
    const formattedDate = date.toLocaleDateString(parsedLang, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return `${weeks} week${weeks > 1 ? "s" : ""} ago (${formattedDate})`;
  } else if (days > 0) {
    // Less than a week but more than a day
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    // Less than a day but more than an hour
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    // Less than an hour but more than a minute
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    // Less than a minute
    return "Just now";
  }
}

export function getDeadlineInfo(dateString) {
  const now = new Date();
  const deadline = new Date(dateString);

  // Normalize both dates to ignore time
  now.setHours(0, 0, 0, 0);
  deadline.setHours(0, 0, 0, 0);

  const diffTime = deadline - now;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const formattedDate = formatDate(dateString);

  // Define conditions and messages
  const conditions = [
    {
      check: () => diffDays === 0,
      message: `${formattedDate} (Due today)`,
    },
    {
      check: () => diffDays === 1,
      message: `${formattedDate} (Due tomorrow)`,
    },
    {
      check: () => diffDays === -1,
      message: `${formattedDate} (Due yesterday)`,
    },
    {
      check: () => diffDays < -1 && diffDays >= -7,
      message: `${formattedDate} (Due ${Math.abs(diffDays)} day${
        Math.abs(diffDays) > 1 ? "s" : ""
      } ago)`,
    },
    {
      check: () => diffDays < -7 && diffDays >= -14,
      message: `${formattedDate} (Due 1 week ago)`,
    },
    {
      check: () => diffDays < -14,
      message: `${formattedDate} (Due ${Math.floor(
        Math.abs(diffDays) / 7
      )} week${Math.floor(Math.abs(diffDays) / 7) > 1 ? "s" : ""} ago)`,
    },
    {
      check: () => diffDays > 1 && diffDays <= 7,
      message: `${formattedDate} (Due in ${diffDays} day${
        diffDays > 1 ? "s" : ""
      })`,
    },
    {
      check: () => diffDays > 7 && diffDays <= 14,
      message: `${formattedDate} (Due in 1 week)`,
    },
    {
      check: () => diffDays > 14,
      message: `${formattedDate} (Due in ${Math.floor(diffDays / 7)} week${
        Math.floor(diffDays / 7) > 1 ? "s" : ""
      })`,
    },
  ];

  // Find the condition that matches
  const result =
    conditions.find((condition) => condition.check())?.message ||
    `${formattedDate} (Unknown deadline status)`;

  return result;
}
