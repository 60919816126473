import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function addStatus(data) {
  const socket = getSocket();
  const { columnName, domainID } = data;

  const config = {
    method: "post",
    url: `/status/${domainID}`,
    data: {
      name: columnName,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-column-announcement", {
    domain: domainID,
    message: `New column added`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function addMultipleStatuses(data) {
  const socket = getSocket();
  const { columnData, domainID } = data;

  const config = {
    method: "post",
    url: `/status/${domainID}/multiple-status`,
    data: columnData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-column-announcement", {
    domain: domainID,
    message: `Multiple columns added`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function editStatus(data) {
  const socket = getSocket();
  const { columnName, domainID, statusID } = data;

  const config = {
    method: "patch",
    url: `/status/${domainID}/${statusID}`,
    data: { name: columnName },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-column-announcement", {
    domain: domainID,
    message: `Column edited`,
  });

  if (error) return handleApiError(error);
  return { success: true, data: responseData };
}

export async function deleteStatus(data) {
  const socket = getSocket();
  const { domainID, statusID } = data;

  const config = {
    method: "delete",
    url: `/status/${domainID}/${statusID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-column-announcement", {
    domain: domainID,
    message: `Column deleted`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function fetchStatuses(domainID) {
  const config = {
    method: "get",
    url: `/status/${domainID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
