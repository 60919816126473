import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialPanelState = {
  loading: false,
  error: null,
  panels: [],
  activePanel: {},
  isAddPanelOpen: false,
  showPanelMembers: false,
};

export const usePanelStore = create(
  persist(
    (set) => ({
      ...initialPanelState,
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),
      setPanels: (panels) => set({ panels }),
      setAddPanel: (isAddPanelOpen) => set({ isAddPanelOpen }),
      setActivePanel: (activePanel) => set({ activePanel }),
      setShowPanelMembers: (showPanelMembers) => set({ showPanelMembers }),
      reset: () => set(initialPanelState),
    }),
    { name: "panel-storage" }
  )
);

const initialPanelInviteState = {
  inviteLoading: false,
  inviteError: null,
  isInvitePanelOpen: false,
};

export const usePanelInviteStore = create((set) => ({
  ...initialPanelInviteState,
  setInviteLoading: (inviteLoading) => set({ inviteLoading }),
  setError: (inviteError) => set({ inviteError }),
  setInvitePanel: (isInvitePanelOpen) => set({ isInvitePanelOpen }),

  reset: () => set(initialPanelInviteState),
}));

const initialPanelRemoveState = {
  removeMembersLoading: false,
  inviteError: null,
  isRemovePanelOpen: false,
};

export const usePanelRemoveStore = create((set) => ({
  ...initialPanelRemoveState,
  setRemoveMembersLoading: (removeMembersLoading) =>
    set({ removeMembersLoading }),
  setError: (inviteError) => set({ inviteError }),
  setRemovePanel: (isRemovePanelOpen) => set({ isRemovePanelOpen }),

  reset: () => set(initialPanelRemoveState),
}));
