import { FaXmark } from "react-icons/fa6";
import { useDomainStore, useEditDomainStore } from "./domainStore";
import Button from "@/UI/Button";
import { deleteDomain, fetchDomains } from "./domainAPI";
import { fetchPanels } from "../panel/panelAPI";
import toast from "react-hot-toast";
import { usePanelStore } from "../panel/panelStore";
import BarLoader from "@/UI/BarLoader";

export default function DeleteDomain() {
  const { curDomain, setDeleteDomain } = useEditDomainStore((store) => store);

  const {
    // activeDomain,
    setDomains,
    setActiveDomain,
    domains,
    setError: setDomainError,
    loading: DomainLoading,
    setLoading: setDomainLoading,
  } = useDomainStore((store) => store);

  const { setPanels, setLoading: setPanelLoading } = usePanelStore(
    (store) => store
  );

  const handleDomainDelete = async () => {
    setDomainLoading(true);

    const index = domains?.findIndex((domain) => domain.id === curDomain.id);

    const deleteResult = await deleteDomain(curDomain.id);

    if (!deleteResult.success) {
      setDomains(domains);
      setDomainError(deleteResult.message);
      setDomainLoading(false);
      return;
    }

    //   Optimistic filtering where delete works but refetching doesn't
    const optimisticDomains = domains?.filter(
      (domain) => domain.id !== curDomain.id
    );

    setDomains(optimisticDomains);

    const fetchResult = await fetchDomains();

    if (!fetchResult.success) {
      setDomainError(fetchResult.message);
      setDomainLoading(false);
      return;
    }

    const newDomains = fetchResult.data;

    setDomains(newDomains);

    if (index > 0) {
      // If the deleted domain wasn't the first one, set the active domain to the previous one
      setActiveDomain(newDomains[index - 1]);
    } else if (index === 0 && newDomains.length > 0) {
      // If the deleted domain was the first one, set the active domain to the next one
      setActiveDomain(newDomains[0]);
    }

    const panelsResult = await fetchPanels(
      newDomains[index > 0 ? index - 1 : 0]?.id
    );

    if (!panelsResult.success) {
      toast.error(panelsResult.message);
      setDomainLoading(false);
      setPanelLoading(false);
      return;
    }

    setPanels(panelsResult.data);
    setDomainLoading(false);
    setDeleteDomain(false);
    setPanelLoading(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h2 className="modal__header text-caution">Delete Domain?</h2>
        <button
          onClick={() => setDeleteDomain(false)}
          disabled={DomainLoading}
          className="p-2 rounded-full cursor-pointer hover:bg-veryDarkGray">
          <FaXmark color="rgba(130, 143, 163, 1)" size={20} />
        </button>
      </div>
      <div className="  font-medium leading-[176%] mb-6">
        <p className=" text-mediumGray text-[18px]">
          Are you sure you want to delete the{" "}
          <span className="font-bold capitalize text-primary">
            {curDomain.name}
          </span>{" "}
          Domain?{" "}
        </p>
      </div>

      <div className="flex flex-col gap-4 md:flex-row">
        <Button
          variant="caution"
          content={DomainLoading ? <BarLoader /> : "Delete Domain"}
          disabled={DomainLoading}
          onClick={handleDomainDelete}
        />
        <Button
          variant="white"
          content={DomainLoading ? <BarLoader /> : "Cancel"}
          disabled={DomainLoading}
          onClick={() => setDeleteDomain(false)}
        />
      </div>
    </div>
  );
}
