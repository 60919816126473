import { createContext, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { SIDEBAR_WIDTH } from "@/utils/constants";

// Create context
export const DynamicWidthContext = createContext();

// Custom hook with a guard clause
export const useDynamicWidthContext = () => {
  const context = useContext(DynamicWidthContext);
  if (!context) {
    throw new Error(
      "useDynamicWidthContext must be used within a DynamicWidthProvider"
    );
  }
  return context;
};

// Provider component
export const DynamicWidthProvider = ({ children }) => {
  const [dynamicWidth, setDynamicWidth] = useState(SIDEBAR_WIDTH);

  const contextValue = useMemo(() => {
    return {
      dynamicWidth,
      setDynamicWidth,
    };
  }, [dynamicWidth, setDynamicWidth]);

  return (
    <DynamicWidthContext.Provider value={contextValue}>
      {children}
    </DynamicWidthContext.Provider>
  );
};

DynamicWidthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
