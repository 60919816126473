// Component imports

import { OnboardingPageWrapper } from "./Onboarding";

// External Libraries
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Store Imports
import { useOnboardingStore } from "./onBoardingStore";
import Button from "@/UI/Button";
import SuccessIcon from "@/icons/SuccessIcon";

export default function OnboardingSuccess({ onboardingState }) {
  // Zustand States
  const setIsOnboarding = useOnboardingStore((store) => store.setIsOnboarding);

  const navigate = useNavigate();

  return (
    <OnboardingPageWrapper
      className="flex flex-col items-center justify-center gap-4 "
      key={onboardingState}>
      <div>
        <span className="">
          <SuccessIcon className="hidden scale-150 lg:block" />
        </span>
        <span>
          <SuccessIcon className="block lg:hidden" />
        </span>
      </div>
      <h3 className="mt-4 text-lg font-semibold text-center md:font-bold md:text-2xl">
        Profile Setup Completed
        <br /> Successfully!!
      </h3>
      <Button
        content="Go to Homepage"
        className="px-4 py-2 mt-4 font-regular w-max"
        onClick={() => {
          navigate("/home");
          setIsOnboarding(false);
        }}
      />
    </OnboardingPageWrapper>
  );
}

OnboardingSuccess.propTypes = {
  onboardingState: PropTypes.number,
};
