// External Libraries
import { BiPlus } from "react-icons/bi";
import PropTypes from "prop-types";

Button.propTypes = {
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.any,
};

const buttonVariants = {
  primary:
    " w-full  leading-6  bg-primary hover:bg-primaryHover  hover:text-white",
  secondary:
    "flex items-center justify-center gap-1 px-6   border hover:bg-primaryHover border-lines",
  add: "px-4 py-1 xs:px-5 xs:flex items-center justify-center gap-1 md:px-6 md:py-3  bg-primary hover:bg-primaryHover",
  white: " w-full hover:bg-[#cccccc] !text-primary  leading-6  bg-bgHover",
  caution: " w-full   leading-6  bg-caution hover:bg-cautionHover",
};

export default function Button({
  variant = "primary",
  icon,
  content,
  onClick,
  className,
  disabled,
  type,
}) {
  const variantClass = buttonVariants[variant] || buttonVariants.primary;

  return (
    <button
      className={`${variantClass} text-center transition-all duration-200 font-medium text-textColor rounded-full py-2 text-[15px] ${className} ${
        disabled ? "disabled:bg-mediumGray disabled:cursor-wait" : ""
      }`}
      onClick={() => !disabled && onClick?.()}
      disabled={disabled}
      type={type}>
      {variant === "add" && (
        <span className="md:hidden">
          <BiPlus size={20} />
        </span>
      )}
      {icon && variant !== "add" && <span>{icon}</span>}
      {content && <span>{content}</span>}
    </button>
  );
}

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "add", "white", "caution"]),
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
