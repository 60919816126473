import { create } from "zustand";

export const useViewTaskStore = create((set) => ({
  isViewTaskOpen: false,
  selectedTask: null,
  status: "",
  isKebabOpen: false,
  isEditTaskOpen: false,

  setIsViewTaskOpen: (isViewTaskOpen) => set({ isViewTaskOpen }),
  setSelectedTask: (selectedTask) => set({ selectedTask }),
  setStatus: (status) => set({ status }),
  setIsKebabOpen: (isKebabOpen) => set({ isKebabOpen }),
  setIsEditTaskOpen: (isEditTaskOpen) => set({ isEditTaskOpen }),
}));
