// Store Imports
import {
  useDomainStore,
  useEditDomainStore,
} from "@/features/domain/domainStore";
import { useAnnouncementsStore } from "../announcements/announcementsStore";
import { useCollaboratorsStore } from "../collaborators/collaboratorsStore";
import { useColumnStore } from "../column/columnStore";
import { usePanelStore } from "../panel/panelStore";
import { useProfileStore } from "../user/profile/profileStore";

// External Libraries
import { useAccessibleClick, useClickOutside } from "@/hooks/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import PropTypes from "prop-types";
import { FaTrash, FaXmark } from "react-icons/fa6";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// API Hooks
import { fetchAnnouncements } from "../announcements/announcementsAPI";
import { fetchCollaborators } from "../collaborators/collaboratorsAPI";
import { fetchStatuses } from "../column/columnAPI";
import {
  fetchDomainAnalytics,
  fetchDomainAnalyticsAverageTime,
  fetchDomainAnalyticsTotalTime,
} from "../domain/domainAPI";
import { fetchPanels } from "../panel/panelAPI";

// React imports
import { useRef, useState } from "react";

// Animation configs
import { dropdownVariants } from "@/utils/framerAnimationConfigs";
import toast from "react-hot-toast";
import { useAnalyticsStore } from "../analytics/analyticsStore";

// Static files
import KebabIcon from "@/assets/Kebab_menu.svg";
import ModalWrapper from "@/UI/ModalWrapper";
import { FaEdit } from "react-icons/fa";
import DeleteDomain from "../domain/DeleteDomain";
import EditDomain from "../domain/EditDomain";
import {
  fetchAnnouncementNotifications,
  fetchTaskNotifications,
} from "../notifications/notificationsAPI";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "../notifications/notificationsStore";
import { useTaskStore } from "../tasks/tasksStore";
import { formatAndStoreAnalytics } from "@/utils/utils";

export default function DomainDropdown() {
  // Zustand States
  const { panels, setPanels } = usePanelStore((store) => store);
  const {
    domains,
    activeDomain,
    setActiveDomain,
    setLoading: setDomainLoading,
    setAddDomain,
  } = useDomainStore((store) => store);

  const {
    setEditDomain,
    isEditDomainOpen,
    isDeleteDomainOpen,
    setDeleteDomain,
  } = useEditDomainStore((store) => store);

  const { setColumns, setError: setColumnError } = useColumnStore(
    (store) => store
  );

  const { setError: setPanelError } = usePanelStore((store) => store);

  const {
    setCollaborators,
    setError: setCollaboratorsError,
    setLoading: setCollaboratorsLoading,
  } = useCollaboratorsStore((store) => store);

  const {
    setAnnouncements,
    setError: setAnnouncementsError,
    setLoading: setAnnouncementsLoading,
  } = useAnnouncementsStore((store) => store);

  const {
    setAnnouncementNotifications,
    setError: setAnnouncementNotificationsError,
    setLoading: setAnnouncementNotificationsLoading,
  } = useAnnouncementNotificationsStore((store) => store);

  const {
    setTaskNotifications,
    setError: setTaskNotificationError,
    setLoading: setTaskNotificationsLoading,
  } = useTaskNotificationsStore((store) => store);

  const {
    setLoading: setAnalyticsLoading,
    setError: setAnalyticsError,
    setTotalTimeError,
    setAverageTimeError,
    analytics,
    setAnalytics,
    setActiveAnalyticsId,
  } = useAnalyticsStore((store) => store);

  // React States and Refs
  const [open, setOpen] = useState(false);
  const dropdownContainerRef = useRef(null);
  const [openKebabId, setOpenKebabId] = useState(null);

  const navigate = useNavigate();

  // Close dropdown
  useClickOutside(dropdownContainerRef, () => {
    setOpen(false);
    setOpenKebabId(null);
  });

  // Function to set domain
  const handleOptionClick = async (domain) => {
    setOpen(false);

    // Check if the clicked domain is already the active domain
    if (activeDomain.name === domain.name) {
      toast.error("You're already in this domain");
      return;
    }

    navigate("/home");

    setActiveDomain(domain);

    const previousDomain = activeDomain;
    setDomainLoading(true);
    setAnnouncementsLoading(true);
    setCollaboratorsLoading(true);
    setAnnouncementNotificationsLoading(true);
    setTaskNotificationsLoading(true);

    const panelsResult = await fetchPanels(domain.id);

    if (!panelsResult.success) {
      // Revert to the previous state if fetching panels fails
      setActiveDomain(previousDomain);
      setPanels(panels);
      setPanelError(panelsResult.message);
      setDomainLoading(false);
      setAnnouncementsLoading(false);
      setCollaboratorsLoading(false);
      setAnnouncementNotificationsLoading(false);
      setTaskNotificationsLoading(false);
      return;
    }

    setPanels(panelsResult.data);
    // Clear all tasks when switching domains
    useTaskStore.getState().resetTasks();

    const [
      statusResponse,
      collaboratorsResponse,
      announcementsResponse,
      analyticsResponse,
      analyticsTotalTimeResponse,
      analyticsAverageTimeResponse,
      announcementNotifications,
      taskNotifications,
    ] = await Promise.all([
      fetchStatuses(domain.id),
      fetchCollaborators(domain.id),
      fetchAnnouncements(domain.id),
      fetchDomainAnalytics(domain.id),
      fetchDomainAnalyticsTotalTime(domain.id, "last5Days"),
      fetchDomainAnalyticsAverageTime(domain.id, "last5Days"),

      fetchAnnouncementNotifications(domain.id),
      fetchTaskNotifications(domain.id),
    ]);

    // Status Handler
    if (!statusResponse.success) setColumnError(statusResponse.message);

    if (statusResponse.success) setColumns(statusResponse.data);

    // Collaborators Handler
    if (!collaboratorsResponse.success)
      setCollaboratorsError(collaboratorsResponse.message);

    if (collaboratorsResponse.success)
      setCollaborators(collaboratorsResponse.data);

    // Announcements Handler
    if (!announcementsResponse.success) {
      setAnnouncementsError(announcementsResponse.message);
    }

    if (announcementsResponse.success)
      setAnnouncements(announcementsResponse.data);

    if (!analyticsResponse.success) {
      setAnalyticsLoading(false);
      setAnalyticsError(analyticsResponse.message);
      setAnalytics(analytics);
      toast.error(analyticsResponse.message);
    }

    if (analyticsResponse.success) {
      setAnalytics(analyticsResponse.data);
      setActiveAnalyticsId(analyticsResponse.data.domainId);
    }

    if (!analyticsTotalTimeResponse.success) {
      setTotalTimeError(analyticsTotalTimeResponse.message);
    }
    if (analyticsTotalTimeResponse.success) {
      formatAndStoreAnalytics(
        analyticsTotalTimeResponse.data,
        "5D",
        "totalTimeSpent"
      );
    }
    if (!analyticsAverageTimeResponse.success) {
      setAverageTimeError(analyticsAverageTimeResponse.message);
    }

    if (analyticsAverageTimeResponse.success) {
      formatAndStoreAnalytics(
        analyticsAverageTimeResponse.data,
        "5D",
        "averageTimeSpent" // Specify the type of data
      );
    }

    if (!announcementNotifications.success)
      setAnnouncementNotificationsError(announcementNotifications.message);

    if (announcementNotifications.success)
      setAnnouncementNotifications(announcementNotifications.data);

    if (!taskNotifications.success)
      setTaskNotificationError(taskNotifications.message);

    if (taskNotifications.success) setTaskNotifications(taskNotifications.data);

    setDomainLoading(false);
    setAnnouncementsLoading(false);
    setCollaboratorsLoading(false);
    setAnnouncementNotificationsLoading(false);
    setTaskNotificationsLoading(false);
  };

  return (
    <>
      <div className="flex items-center justify-center relative z-[100]">
        <div className="relative z-[100]" ref={dropdownContainerRef}>
          <button
            onClick={() => setOpen((prev) => !prev)}
            className="flex items-center gap-2 px-3 py-2 transition-colors rounded-md text-textColor hover:bg-primaryIdle">
            <span className="text-base capitalize !font-medium sm:text-lg md:text-xl lg:text-2xl">
              {activeDomain?.name ? activeDomain?.name : domains?.[0]?.name}
            </span>
            <span className=" translate-y-[10%] ">
              <ChevronDown color="rgba(99, 95, 199, 1)" />
            </span>
          </button>

          <AnimatePresence>
            {open && (
              <ul className=" absolute  top-[120%] left-[70%] sm:left-[100%]  z-[100] w-max -translate-x-1/2 xl:min-w-[225px] ">
                <motion.div
                  initial={dropdownVariants.initial}
                  animate={dropdownVariants.animate}
                  exit={dropdownVariants.exit}
                  key={open}
                  className="grid gap-2 border border-lines bg-veryDarkGray p-4 sm:py-[18px] sm:px-[22px]  rounded-md  shadow-xl ">
                  {domains?.map((domain) => {
                    return (
                      <Option
                        domain={domain}
                        key={domain.id}
                        setOpen={setOpen}
                        openKebabId={openKebabId}
                        setOpenKebabId={setOpenKebabId}
                        handleOptionClick={() => {
                          handleOptionClick(domain);
                        }}
                      />
                    );
                  })}

                  <button
                    className={`flex text-md items-center gap-1 p-2 sm:p-3 sm:font-medium transition-all rounded-md cursor-pointer bg-primaryIdleHover hover:bg-primary `}
                    onClick={() => {
                      setAddDomain(true);
                      setOpen(false);
                    }}>
                    <span>+</span>
                    <span className="text-[14px] sm:text-base font-regular">
                      {" "}
                      Add Another Domain
                    </span>
                  </button>
                </motion.div>
              </ul>
            )}
          </AnimatePresence>
        </div>
      </div>
      <ModalWrapper isOpen={isEditDomainOpen} onClose={setEditDomain}>
        <EditDomain />
      </ModalWrapper>
      <ModalWrapper isOpen={isDeleteDomainOpen} onClose={setDeleteDomain}>
        <DeleteDomain />
      </ModalWrapper>
    </>
  );
}

const Option = ({
  domain,
  handleOptionClick,
  openKebabId,
  setOpenKebabId,
  setOpen,
}) => {
  // Zustand States

  const { activeDomain, domains } = useDomainStore((store) => store);

  const userId = useProfileStore((state) => state?.userData.id);

  const { setEditDomain, setCurDomain, setDeleteDomain } = useEditDomainStore(
    (store) => store
  );

  const isOpen = openKebabId === domain.id;

  // all domains owned by you
  const allOwned = domains.map((d) => d.ownerId === userId);

  const handleClick = () => handleOptionClick();
  // const handleDeleteClick = (e) => {
  //   e?.stopPropagation(); // Prevent the event from bubbling up
  //   handleDelete(domain.id);
  // };

  const handleClickKeyDown = useAccessibleClick(handleClick);
  // const handleDeleteKeyDown = useAccessibleClick(handleDeleteClick);

  const handleKebabClick = (e) => {
    e?.stopPropagation();
    setOpenKebabId(domain.id);
  };

  return (
    <li
      className={` font-medium capitalize  w-full gap-10 p-2  text-md transition-colors rounded border-[1px] border-[#828FA340]  whitespace-nowrap break-words text-textColor   ${
        domain?.name === activeDomain?.name && "bg-veryDarkGray"
      }`}
      onClick={handleOptionClick}
      onKeyDown={handleClickKeyDown} // Accessibility for click
      tabIndex={0} // Make the element focusable
      role="button" // Indicate that this is a clickable element
    >
      <div className="flex items-center justify-between p-2 rounded-md hover:bg-primaryIdleHover">
        <div className="flex gap-1 max-w-[75%] text-wrap">
          <span className="translate-y-1">
            {domain?.id === activeDomain?.id && (
              <FiCheck color="#635fc7" strokeWidth={3} />
            )}
          </span>

          <span className="text-[14px] sm:text-base font-regular">
            {domain?.name}
          </span>
        </div>

        {domain.ownerId === userId && (
          <div
            className=" w-[30px] flex  aspect-square rounded-full justify-center items-center "
            onClick={handleKebabClick}>
            {isOpen ? (
              <span
                className="p-2 rounded-full hover:bg-veryDarkGray"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenKebabId(null);
                }}>
                <FaXmark color="#828FA3" size={16} />
              </span>
            ) : (
              <span className="flex items-center justify-center w-8 rounded-full aspect-square hover:bg-veryDarkGray">
                <img src={KebabIcon} className="w-1" alt="Kebab Icon" />
              </span>
            )}
          </div>
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="p-1 mt-2 space-y-2 rounded-md bg-darkGray "
            initial={{ height: "0", opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            exit={{ height: "0", opacity: 0 }}>
            <div
              className="flex gap-2 p-2 rounded-md text-mediumGray hover:bg-primaryIdleHover hover:text-textColor"
              onClick={() => {
                setEditDomain(true);
                setOpen(false);
                setCurDomain(domain);
                setOpenKebabId(null);
              }}>
              <FaEdit size={16} />
              <span>Edit Domain</span>
            </div>
            {allOwned.length > 1 && (
              <div
                className="flex gap-2 p-2 rounded-md hover:bg-primaryIdleHover text-mediumGray hover:text-cautionHover"
                onClick={() => {
                  setDeleteDomain(true);
                  setOpen(false);
                  setCurDomain(domain);
                  setOpenKebabId(null);
                }}>
                <FaTrash size={16} />
                <span> Delete Domain</span>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};

Option.propTypes = {
  domain: PropTypes.object,
  handleOptionClick: PropTypes.func,
  openKebabId: PropTypes.any,
  setOpenKebabId: PropTypes.func,
  setOpen: PropTypes.func,
};
