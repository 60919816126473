// Component imports
import Button from "@/UI/Button";
import Dropdown from "@/UI/DropdownPicker";
import { OnboardingPageWrapper } from "./Onboarding";

// Constants
import { DEPARTMENTS, JOB_TITLES } from "@/utils/constants";

// External Libraries
import PropTypes from "prop-types";
import toast from "react-hot-toast";

// Context
import { useOnboardingContext } from "./OnboardingContext";

// React imports
import { useState } from "react";

const OnboardingAccount = ({ onNext, onboardingState }) => {
  const {
    handleChange,
    modifiedUser,
    selectedDepartment,
    setSelectedDepartment,
    selectedJobTitle,
    setSelectedJobTitle,
  } = useOnboardingContext();

  const { username } = modifiedUser;

  const [localUsername, setLocalUsername] = useState(username);

  return (
    <OnboardingPageWrapper
      className="flex flex-col gap-8"
      key={onboardingState}>
      <div>
        <h2 className="text-xl text-bold">Work Info</h2>
        <p className="mb-4 text-mediumGray">
          Include your Work Details and Information
        </p>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <Dropdown
          selectedItem={selectedJobTitle}
          setSelectedItemAction={(jobTitle) => {
            setSelectedJobTitle(jobTitle);
            handleChange("jobTitle", jobTitle.name);
          }}
          items={JOB_TITLES}
          label="Job title">
          <Dropdown.Header />
          <Dropdown.Button />
          <Dropdown.List
            direction="bottom"
            className="!bg-darkGray"
            searchClass=" !bg-darkGray"
          />
        </Dropdown>

        <Dropdown
          selectedItem={selectedDepartment}
          setSelectedItemAction={(department) => {
            setSelectedDepartment(department);
            handleChange("department", department.name);
          }}
          items={DEPARTMENTS}
          label="Department">
          <Dropdown.Header />
          <Dropdown.Button />
          <Dropdown.List
            direction="bottom"
            className="!bg-darkGray"
            searchClass=" !bg-darkGray"
          />
        </Dropdown>
      </div>

      <div className="flex flex-col grid-cols-2 gap-4 md:grid ">
        <div>
          <label className="block mb-2 text-sm" htmlFor="username">
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            value={localUsername}
            onChange={(e) => setLocalUsername(e.target.value)}
            required
            className="text-[13px] font-medium input__style p-2.5 hover:border-primary focus:outline-none"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <Button
          content="Next"
          className="px-8 py-2 w-max"
          onClick={() => {
            if (localUsername.length >= 3) {
              handleChange("username", localUsername);
              onNext();
            } else {
              toast.error(
                "Please enter a valid username longer than 3 letters"
              );
            }
          }}
        />
      </div>
    </OnboardingPageWrapper>
  );
};

export default OnboardingAccount;

OnboardingAccount.propTypes = {
  onNext: PropTypes.func,
  onboardingState: PropTypes.number,
};
