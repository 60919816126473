// Component imports
import BarLoader from "@/UI/BarLoader";
import AuthLayout from "../AuthLayout";

// External Libraries
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

// Custom Hooks
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

// Store Imports
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { useColumnStore } from "@/features/column/columnStore";
import { useDomainStore } from "@/features/domain/domainStore";
import { usePanelStore } from "@/features/panel/panelStore";
import { useProfileStore } from "@/features/user/profile/profileStore";

// React imports
import { useAnalyticsStore } from "@/features/analytics/analyticsStore";
import { fetchAnnouncements } from "@/features/announcements/announcementsAPI";
import { useAnnouncementsStore } from "@/features/announcements/announcementsStore";
import {
  fetchAnnouncementNotifications,
  fetchTaskNotifications,
} from "@/features/notifications/notificationsAPI";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "@/features/notifications/notificationsStore";
import { auth, googleProvider } from "@/firebase/firebaseConfig";
import { useAccessibleClick } from "@/hooks/hooks";
import GoogleIcon from "@/icons/GoogleIcon";
import { initializeSocket } from "@/sockets/socket";
import Button from "@/UI/Button";
import { useSessionStore } from "@/UI/sessionsStore";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import toast from "react-hot-toast";
import { googleSignIn } from "../authApi";
import { formatAndStoreAnalytics } from "@/utils/utils";
import { useOnboardingStore } from "@/features/onboarding/onBoardingStore";

export default function Login() {
  // Zustand States
  const { setDomains, setActiveDomain } = useDomainStore((state) => state);

  const { setUserData } = useProfileStore((state) => state);

  const setPanels = usePanelStore((store) => store.setPanels);

  const setColumns = useColumnStore((store) => store.setColumns);

  const setCollaborators = useCollaboratorsStore(
    (store) => store.setCollaborators
  );

  const { setAnalytics, setActiveAnalyticsId } = useAnalyticsStore(
    (store) => store
  );

  const {
    setAnnouncementNotifications,
    setError: setAnnouncementNotificationsError,
    setLoading: setAnnouncementNotificationLoading,
  } = useAnnouncementNotificationsStore((store) => store);

  const {
    setTaskNotifications,
    setError: setTaskNotificationsError,
    setLoading: setTaskNotificationLoading,
  } = useTaskNotificationsStore((store) => store);

  const setSessionExpired = useSessionStore((store) => store.setSessionExpired);

  const setIsOnboarding = useOnboardingStore((store) => store.setIsOnboarding);

  const {
    setAnnouncements,
    setLoading: setAnnouncementLoading,
    setError: setAnnouncementsError,
  } = useAnnouncementsStore((store) => store);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    const config = {
      url: "/auth/signin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email.toLowerCase(),
        password,
        rememberMe,
      },
    };

    const { data, error } = await apiRequestWithTimeout(config);

    if (error) {
      const errorMessage = handleApiError(error);
      console.log(errorMessage);
      setErrorMessage(errorMessage.message);
    }

    if (data) handleSuccess(data);

    setLoading(false);
  };

  const handleSuccess = async (data) => {
    const { user_data, domains, access_token } = data;
    const firstDomain = domains?.domains?.[0];

    // Store the access token
    localStorage.setItem("kreed_at", access_token);

    // Set states with extracted data
    setUserData(user_data);
    setDomains(domains?.domains);
    setActiveDomain(firstDomain);
    setPanels(domains?.panels);
    setColumns(firstDomain?.status);
    setCollaborators(domains?.members);
    // setAnalytics(firstDomain.id, domains?.analytics);
    setActiveAnalyticsId(firstDomain.id);
    setSessionExpired(false);

    // Extract analytics data
    // Extract analytics data from the response
    const { totalTimeAnalyticsFor5Days, averageTimeAnalyticsFor5Days } =
      domains.analytics;

    setAnalytics(domains.analytics.analytics);

    // Format and store totalTimeSpent under '5D'
    formatAndStoreAnalytics(
      totalTimeAnalyticsFor5Days,
      "5D", // Key for the time range
      "totalTimeSpent" // Type of analytics being stored
    );

    // Format and store averageTimeSpent under '5D'
    formatAndStoreAnalytics(
      averageTimeAnalyticsFor5Days,
      "5D", // Key for the time range
      "averageTimeSpent" // Type of analytics being stored
    );

    if (user_data.availableHoursFrom === null) setIsOnboarding(true);

    // Navigate to the home page
    navigate("/home");

    const [
      newAnnouncementNotifications,
      newTaskNotifications,
      domainAnnouncements,
    ] = await Promise.all([
      fetchAnnouncementNotifications(firstDomain.id),
      fetchTaskNotifications(firstDomain.id),
      fetchAnnouncements(firstDomain.id),
    ]);

    setAnnouncementNotificationLoading(true);
    setTaskNotificationLoading(true);
    setAnnouncementLoading(true);

    if (!newAnnouncementNotifications.success)
      setAnnouncementNotificationsError(newAnnouncementNotifications.message);

    if (!newTaskNotifications.success)
      setTaskNotificationsError(newTaskNotifications.message);

    if (!domainAnnouncements.success)
      setAnnouncementsError(newTaskNotifications.message);

    setAnnouncementNotifications(newAnnouncementNotifications.data);

    setTaskNotifications(newTaskNotifications.data);
    setAnnouncements(domainAnnouncements.data);

    setAnnouncementNotificationLoading(false);
    setTaskNotificationLoading(false);
    setAnnouncementLoading(false);

    const params = { userId: user_data.id, domainId: firstDomain.id };

    initializeSocket(params);
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const userCredentials = await signInWithPopup(auth, googleProvider);
      const user = userCredentials.user;

      const accessToken = await user.getIdToken();

      console.log("Access Token", accessToken);

      if (!accessToken) {
        setLoading(false);
        return;
      }
      console.log(
        "User signed up with Google:",
        userCredentials,
        user,
        accessToken
      );

      const googleResult = await googleSignIn(accessToken);
      console.log("Google result", googleResult);

      if (!googleResult?.success) {
        toast.error("Error Logging you in, try again");
        setLoading(false);
        return;
      }

      const { user_data, domains, access_token } = googleResult.data;
      const firstDomain = domains?.domains?.[0];

      const params = { userId: user_data.id, domainId: firstDomain.id };

      initializeSocket(params);

      // Store the access token
      localStorage.setItem("kreed_at", access_token);

      // Set states with extracted data
      setUserData(user_data);
      setDomains(domains?.domains);
      setActiveDomain(firstDomain);
      setPanels(domains?.panels);
      setColumns(firstDomain?.status);
      setCollaborators(domains?.members);
      // setAnalytics(domains?.analytics.domainId, domains?.analytics);
      setActiveAnalyticsId(firstDomain.id);

      // Store analytics object
      setAnalytics(domains.analytics);

      setLoading(false);

      if (user_data.availableHoursFrom === null) setIsOnboarding(true);

      // Navigate to the home page
      navigate("/home");

      const [
        newAnnouncementNotifications,
        newTaskNotifications,
        domainAnnouncements,
      ] = await Promise.all([
        fetchAnnouncementNotifications(firstDomain.id),
        fetchTaskNotifications(firstDomain.id),
        fetchAnnouncements(firstDomain.id),
      ]);

      setAnnouncementNotificationLoading(true);
      setTaskNotificationLoading(true);
      setAnnouncementLoading(true);

      if (!newAnnouncementNotifications.success)
        setAnnouncementNotificationsError(newAnnouncementNotifications.message);

      if (!newTaskNotifications.success)
        setTaskNotificationsError(newTaskNotifications.message);

      if (!domainAnnouncements.success)
        setAnnouncementsError(newTaskNotifications.message);

      setAnnouncementNotifications(newAnnouncementNotifications.data);

      setTaskNotifications(newTaskNotifications.data);
      setAnnouncements(domainAnnouncements.data);

      setAnnouncementNotificationLoading(false);
      setTaskNotificationLoading(false);
      setAnnouncementLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error during Google signup:", error.message);
    }
  };

  return (
    <AuthLayout
      title="Login"
      linkTo="/auth/signup"
      linkText="Don't have an account yet ?">
      <p className=" mt-1 text-[14px] sm:text-base">
        Enter your details to access your account
      </p>
      <button
        className={`flex items-center justify-center gap-2 my-4 google__button disabled:cursor-not-allowed`}
        disabled={loading}
        onClick={handleGoogleSignIn}>
        <span>
          {/* <img src={GoogleIcon} alt="" className="w-6 aspect-square" /> */}
          <GoogleIcon size="24" />
        </span>{" "}
        Log in with Google
      </button>
      <div className="relative flex justify-center mb-4 capitalize divider__lines">
        Or
      </div>
      <form onSubmit={loginUser} className="flex flex-col gap-4 ">
        <div>
          <label
            htmlFor="email"
            className="block mb-2 font-normal text-[14px] text-textColor">
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your Email"
            className="input__style"
          />
        </div>

        <div className="relative ">
          <label
            htmlFor="password"
            className="block mb-2 font-normal text-[14px] text-textColor">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your Password"
              className="input__style"
              autoComplete="true"
            />
            <span
              role="button"
              tabIndex={0}
              className="absolute -translate-y-1/2 cursor-pointer right-4 top-1/2"
              onClick={() => setShowPassword(!showPassword)}
              onKeyDown={useAccessibleClick(() =>
                setShowPassword(!showPassword)
              )}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <p className="absolute right-0 text-xs font-bold cursor-pointer top-1 text-primary">
            Forgot Password
          </p>
          {errorMessage && (
            <p className="text-xs mt-1.5 text-cautionHover">{errorMessage}</p>
          )}
        </div>
        <div className="flex flex-row-reverse items-center justify-end gap-1">
          <label
            htmlFor="remember-details"
            className="text-[14px] cursor-pointer sm:text-base font-regular sm:font-lg">
            Remember Information
          </label>

          {/* <Checkbox id="remember-details" /> */}
          <div className="inline-flex items-center">
            <label
              className="relative flex items-center p-3 rounded-full cursor-pointer"
              htmlFor="remember-details">
              <input
                type="checkbox"
                onChange={(e) => setRememberMe(e.target.checked)}
                id="remember-details"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-lines checked:bg-primary checked:before:bg-primary hover:before:opacity-10 before:checked:hover:before:opacity-10 "
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1">
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"></path>
                </svg>
              </span>
            </label>
          </div>
        </div>

        <Button
          className="form__button"
          disabled={loading}
          content={loading ? <BarLoader /> : "Login"}
        />
      </form>
    </AuthLayout>
  );
}
