import { create } from "zustand";

const initialUserSettingsStore = {
  editProfile: false,
  profileLoading: false,
  profileError: null,
  editAccount: false,
};

export const useUserSettingsStore = create((set) => ({
  ...initialUserSettingsStore,
  setEditProfile: (editProfile) => set({ editProfile }),
  setProfileLoading: (profileLoading) => set({ profileLoading }),
  setError: (error) => set({ error }),
  setEditAccount: (editAccount) => set({ editAccount }),
}));
