import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function addTask(data) {
  const socket = getSocket();
  const { taskData, domainID, panelID, assignedUsers } = data;

  const config = {
    method: "post",
    url: `/task/${domainID}/${panelID}`,
    data: taskData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-info", {
    domain: domainID,
    panel: panelID,
    message: `[${panelID}] Task added`,
  });

  socket.emit("send-to-assigned", {
    domain: domainID.id,
    assignedUsers,
    message: "Task Assignment",
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function editTask(data) {
  const socket = getSocket();
  const { taskData, domainID, panelID, taskID } = data;

  const config = {
    method: "patch",
    url: `/task/${domainID}/${panelID}/${taskID}`,
    data: taskData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-info", {
    domain: domainID,
    panel: panelID,
    message: `[${panelID}] Task edited`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function deleteTask(data) {
  const socket = getSocket();
  const { domainID, panelID, taskID } = data;

  const config = {
    method: "delete",
    url: `/task/${domainID}/${panelID}/${taskID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-info", {
    domain: domainID,
    panel: panelID,
    message: `[${panelID}] Task deleted`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function deleteMultipleTasks(data) {
  const socket = getSocket();
  const { domainID, panelID, taskIds, statusID } = data;

  const config = {
    method: "delete",
    url: `/task/${domainID}/${panelID}/${statusID}/tasks-in-column`,
    data: { taskIds },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-info", {
    domain: domainID,
    panel: panelID,
    message: `[${panelID}] Multiple tasks deleted`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function fetchTasks(domainID, panelID) {
  const config = {
    method: "get",
    url: `/task/${domainID}/${panelID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}

export async function editMultipleTasks(data) {
  const socket = getSocket();
  const { taskData, domainID, panelID } = data;

  const config = {
    method: "patch",
    url: `/task/${domainID}/${panelID}/multiple-tasks`,
    data: taskData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-info", {
    domain: domainID,
    panel: panelID,
    message: `[${panelID}] Multiple tasks edited`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
