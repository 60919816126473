import { AnimatePresence, motion } from "framer-motion";
// Static Imports
import No_Result from "@/assets/No_result.svg";
import Avatar from "@/UI/Avatar";
import { formatTimeToLocalTimeString } from "@/utils/dateAndTimeUtils";
import { formatMentions, useMentionClickHandler } from "@/utils/mentionUtils";
import { useCollaboratorsStore } from "../collaborators/collaboratorsStore";
import { useAnnouncementContext } from "./AnnouncementsContext";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useViewProfileStore } from "../user/profile/profileStore";
export default function AnnouncementSearchResult({
  searched,
  foundResults,
  clearSearch,
}) {
  const { scrollToAnnouncement } = useAnnouncementContext() || {};

  const navigate = useNavigate();

  const collaborators = useCollaboratorsStore((store) => store.collaborators);

  const setProfileId = useViewProfileStore((store) => store.setProfileId);

  // Use the custom hook to handle mentions click
  useMentionClickHandler(collaborators, setProfileId, navigate);
  return (
    <AnimatePresence>
      {foundResults.length > 0 && searched && (
        <div className="absolute left-0 w-full translate-y-full -bottom-1.5">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-col h-full gap-2 p-2 pt-0 overflow-y-auto border border-t-0 rounded-md rounded-tl-none rounded-tr-none max-h-96 bg-veryDarkGray border-lines">
            <h2 className="sticky top-0 p-2 font-bold bg-veryDarkGray">
              Announcements ({foundResults.length})
            </h2>
            {foundResults.map((c) => {
              const formattedContent = formatMentions(
                c.content,
                collaborators,
                setProfileId,
                navigate
              );
              return (
                <div
                  key={c.id}
                  className="flex gap-4 p-4 break-words rounded-md cursor-pointer hover:bg-primaryIdle bg-darkGray"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    scrollToAnnouncement(c.id);
                    clearSearch();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault(); // Prevent default behavior for 'Enter' or 'Space'
                      scrollToAnnouncement(c.id);
                      clearSearch();
                    }
                  }}>
                  <Avatar width="3" imgSrc={c.author.profilePicture} />

                  <div>
                    <div className="flex gap-2 text-[13px] font-bold items-center">
                      <span>{c.author.fullName}</span>
                      <span className="text-xs text-mediumGray">
                        {formatTimeToLocalTimeString(c.sentAt)}
                      </span>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formattedContent,
                      }}></p>
                  </div>
                </div>
              );
            })}
          </motion.div>
        </div>
      )}
      {/* When there's no result */}
      {searched && foundResults.length === 0 && (
        <div className="absolute left-0 w-full rounded-tr-none text-center -bottom-1.5 bg-veryDarkGray translate-y-full flex flex-col justify-center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="p-2 space-y-2 border border-t-0 rounded-md rounded-tl-none rounded-tr-none border-lines">
            <div>
              <img src={No_Result} alt="No_Result SVG" className="mx-auto" />
              {/* <NoResultIcon /> */}
            </div>
            <h2 className="text-lg font-bold">
              We couldn&apos;t find any announcements with that message
            </h2>
            <p className="text-[14px] text-mediumGray">
              Try refining your search terms or double check for typos
            </p>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

AnnouncementSearchResult.propTypes = {
  searched: PropTypes.bool.isRequired,
  foundResults: PropTypes.arrayOf(String),
  clearSearch: PropTypes.func.isRequired,
};
