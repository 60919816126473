import { useEffect } from "react";
import { io } from "socket.io-client";

import NotificationSound from "@/assets/socket-notification.mp3";

let socket;

export const initializeSocket = (params) => {
  if (!socket) {
    socket = io("https://api.kreed.tech", {
      query: {
        params: JSON.stringify(params),
      },
    });

    // Handle connection
    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};

export const useSocketListener = (
  eventType,
  fetchFunction, // Function that optionally takes panelID if present
  setDataFunction, // Function to store data
  dependencies,
  needsPanelID = false, // Flag to determine if we need to extract panelID
  retryDelay = 2000, // Retry delay in milliseconds (adjustable)
  maxRetries = 5 // Maximum number of retries before stopping
) => {
  useEffect(() => {
    const handleSocketMessage = async (message) => {
      console.log(`${eventType} Socket Message`, message);

      let panelID = null;

      // Check for panelID early if needed
      if (needsPanelID) {
        const match = message.match(/\[(.*?)\]/); // Extract panelID from message
        panelID = match ? match[1] : null;
      }

      // Recursive function to retry until successful or maxRetries reached
      const attemptFetch = async (retryCount = 0) => {
        // Fetch data: include panelID only if it's available
        const result = panelID
          ? await fetchFunction(panelID)
          : await fetchFunction();

        if (!result.success) {
          console.error(
            `Attempt ${retryCount + 1}: ${eventType} fetch failed.`
          );

          // Stop if maxRetries is reached
          if (retryCount >= maxRetries) {
            console.error(
              `Max retries reached for ${eventType}. Stopping attempts.`
            );
            return;
          }

          // Retry after a delay if the fetch fails
          setTimeout(() => {
            attemptFetch(retryCount + 1); // Increment retry count
          }, retryDelay);
          return;
        }

        console.log(`${eventType} Result`, result.data);

        // Store data: include panelID if available
        if (panelID) setDataFunction(panelID, result.data);
        else setDataFunction(result.data);

        if (eventType === "gen-announcement" || eventType === "to-assigned") {
          const audio = new Audio(NotificationSound);
          audio.play();
        }
      };

      // Initial fetch attempt
      attemptFetch();
    };

    socket?.on(eventType, handleSocketMessage);

    // Clean up the socket listener
    return () => {
      socket?.off(eventType, handleSocketMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
