import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Toaster } from "react-hot-toast";

// React imports
import { lazy, useEffect } from "react";

// Context
import { AnnouncementProvider } from "./features/announcements/AnnouncementsContext";

// Component imports
import AppLayout from "./UI/AppLayout";
import Login from "./features/auth/login/Login";
import NotFoundPage from "./UI/NotFoundPage";
import Signup from "./features/auth/signup/Signup";

// Lazy load components
const HomePage = lazy(() => import("./features/analytics/HomePage"));
const AnnouncementsPage = lazy(() =>
  import("./features/announcements/AnnouncementsPage")
);
const CollaboratorsPage = lazy(() =>
  import("./features/collaborators/CollaboratorsPage")
);
const NotificationsPage = lazy(() =>
  import("./features/notifications/NotificationsPage")
);
const Profile = lazy(() => import("./features/user/profile/Profile"));
const TasksPage = lazy(() => import("./features/tasks/TasksPage"));

const InvitePage = lazy(() => import("./features/invite/InvitePage"));

export default function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <AppRoutes />
    </BrowserRouter>
  );
}

function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("kreed_at");

  useEffect(() => {
    if (
      !accessToken &&
      location.pathname !== "/auth/login" &&
      location.pathname !== "/auth/signup" &&
      location.pathname !== "/invite"
    ) {
      navigate("auth/login");
    }
  }, [accessToken, location, navigate]);

  useEffect(() => {
    if (accessToken && location.pathname.startsWith("/auth")) {
      navigate("/home", { replace: true });
    }
  }, [accessToken, location, navigate]);

  return (
    <Routes location={location}>
      <Route path="invite" element={<InvitePage />} />
      {accessToken ? (
        <>
          <Route index element={<Navigate replace to="home" />} />
          <Route path="/" element={<AppLayout />}>
            <Route path="home" element={<HomePage />} />
            <Route path="/panel/:panelName/:panelID" element={<TasksPage />} />
            <Route path="panel" element={<TasksPage />} />
            <Route
              path="notifications"
              element={
                <AnnouncementProvider>
                  <NotificationsPage />
                </AnnouncementProvider>
              }
            />
            <Route path="collaborators" element={<CollaboratorsPage />} />
            <Route
              path="announcements"
              element={
                <AnnouncementProvider>
                  <AnnouncementsPage />
                </AnnouncementProvider>
              }
            />
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </>
      ) : (
        <>
          <Route path="/auth" element={<Outlet />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
          </Route>
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
}
