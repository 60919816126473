// External Libraries

import { FiSearch } from "react-icons/fi";

// React imports
import { useRef, useState } from "react";

// Store imports
import { usePanelStore } from "../panel/panelStore";
import { useTaskStore } from "./tasksStore";

// Custom hooks
import { useClickOutside, useDebounce } from "@/hooks/hooks";

// Static Imports
import TaskSearchResult from "./TaskSearchResult";
import { DEBOUNCE_DURATION } from "@/utils/constants";

export default function SearchedTasks() {
  // Zustand States
  const activePanel = usePanelStore((store) => store.activePanel);

  const tasks = useTaskStore((store) => store.tasks);

  // Selecting the current tasks via the panel ID
  const activePanelTasks = tasks[activePanel.id] || [];

  // React States
  const [search, setSearch] = useState("");

  const [searched, setSearched] = useState(false); // State to track if search has been attempted

  const [foundTasks, setFoundTasks] = useState([]);

  const formRef = useRef(null);

  const handleTasksSearch = (search) => {
    setSearched(true);

    if (search.trim() === "") {
      setSearched(false); // Do not set searched to true if the value is empty
      setFoundTasks([]);
      return;
    }

    setSearched(true); // Set searched to true only if the value is not empty

    const filteredTasks = activePanelTasks.filter((c) =>
      c.title.toLowerCase().includes(search.toLowerCase())
    );

    setFoundTasks(filteredTasks);
  };

  const debounceDisplayResults = useDebounce(
    handleTasksSearch,
    DEBOUNCE_DURATION
  );

  const clearSearch = () => {
    setSearch("");
    setSearched(false);
    setFoundTasks([]);
  };

  useClickOutside(formRef, () => clearSearch());

  return (
    <form
      ref={formRef}
      className="relative block max-[1200px]:hidden max-w-[100%] w-full lg:w-1/2  transition-all rounded-md border-[1px]  border-lines focus-within:border-primary"
      onSubmit={(e) => e.preventDefault()}>
      <input
        type="text"
        name="search"
        id="search"
        className="w-full p-2 py-2.5 focus:pl-[35px] pl-4 transition-all duration-300  rounded-md bg-darkGray   focus-within:outline-none"
        placeholder="Search for a Task"
        onChange={(e) => {
          const { value } = e.target;
          setSearch(value);
          debounceDisplayResults(value); // Debounce displaying results based on input
        }}
        value={search}
      />
      {search.length >= 3 && (
        <button
          type="submit"
          className="absolute right-0 p-1.5 mr-2 -translate-y-1/2 rounded-full top-1/2 bg-primary">
          <FiSearch color="#fff" />
        </button>
      )}

      <TaskSearchResult
        searched={searched}
        foundResults={foundTasks}
        clearSearch={clearSearch}
      />
    </form>
  );
}
