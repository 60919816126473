// External Libraries
import { useAccessibleClick } from "@/hooks/hooks";
import PropTypes from "prop-types";

import { PiEyeClosedBold } from "react-icons/pi";

export default function ToggleSidebar({ setDynamicWidth, className }) {
  const handleKeyDown = useAccessibleClick(() => setDynamicWidth(0));

  return (
    <div
      className={`cursor-pointer ${className} hover:text-white hover:bg-primary`}
      onClick={() => setDynamicWidth(0)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button">
      <button className="flex items-center gap-2 px-2 py-2">
        <PiEyeClosedBold />
        <span className="text-base font-medium">Hide Sidebar</span>
      </button>
    </div>
  );
}

ToggleSidebar.propTypes = {
  setDynamicWidth: PropTypes.func,
  className: PropTypes.string,
};
