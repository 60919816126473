import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function fetchAnnouncementNotifications(domainID) {
  const config = {
    method: "get",
    url: `/notifications/announcements/${domainID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);
  return { success: true, data };
}

export async function fetchTaskNotifications(domainID) {
  const config = {
    method: "get",
    url: `/notifications/tasks/${domainID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}

export async function editNotification(notificationID) {
  const config = {
    method: "patch",
    url: `/notifications/${notificationID}`,
    data: { hasRead: true },
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}
export async function deleteNotification(notificationID) {
  const config = {
    method: "delete",
    url: `/notifications/${notificationID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}
