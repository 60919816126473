import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialAccountState = {
  userAccount: {
    language: "",
    availableHoursFrom: "",
    availableHoursTo: "",
    editAccount: false,
    loading: false,
    error: null,
  },
};

export const useAccountStore = create(
  persist(
    (set) => ({
      ...initialAccountState,
      setUserAccount: (newData) =>
        set((state) => ({ userAccount: { ...state.userAccount, ...newData } })),
      reset: () => set(initialAccountState),
    }),
    { name: "account-storage" }
  )
);
