import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function addPanel(data) {
  const socket = getSocket();
  const { panelName, domainID, authorID } = data;

  const config = {
    method: "post",
    url: `/panels/${domainID}`,
    data: {
      name: panelName,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-created-panel", {
    domain: domainID,
    authorId: authorID,
    message: "Add Panel Socket Message",
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function inviteUserToPanel(data) {
  const socket = getSocket();
  const { userIds, domainID, panelID } = data;

  const config = {
    method: "post",
    url: `/panels/${domainID}/${panelID}`,
    data: { userIds },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("panel-modify-announcement", {
    domain: domainID,
    panel: panelID,
    users: userIds,
    message: `[${panelID}] invited`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function removeUsersFromPanel(data) {
  const socket = getSocket();
  const { userIds, domainID, panelID } = data;

  const config = {
    method: "delete",
    url: `/panels/${domainID}/${panelID}/remove-users`,
    data: { panelMembersId: userIds },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("panel-modify-announcement", {
    domain: domainID,
    panel: panelID,
    users: userIds,
    message: `[${panelID}] removed`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function editPanel(data) {
  const socket = getSocket();
  const { panelName, domainID, panelID } = data;

  const config = {
    method: "patch",
    url: `/panels/${domainID}/${panelID}`,
    data: { name: panelName },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-panel-announcement", {
    domain: domainID,
    panel: panelID,
    message: ` Panel edited`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function deletePanel(data) {
  const socket = getSocket();
  const { domainID, panelID } = data;

  const config = {
    method: "delete",
    url: `/panels/${domainID}/${panelID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-panel-announcement", {
    domain: domainID,
    panel: panelID,
    message: ` Panel deleted`,
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function fetchPanels(domainID) {
  const config = {
    method: "get",
    url: `/panels/${domainID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function fetchPanelData(data) {
  const { domainID, panelID } = data;

  const config = {
    method: "get",
    url: `/panels/${domainID}/${panelID}`,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
