// External Libraries

import { AnimatePresence, motion } from "framer-motion";

import PropTypes from "prop-types";

export default function ModalWrapper({ isOpen, onClose, children, className }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => onClose?.(false)}
          className={`fixed inset-0 z-[70] grid p-2 sm:p-8  cursor-pointer bg-[rgba(0,0,0,0.5)]  place-items-center  `}>
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className={`relative w-full  max-w-lg p-4 sm:p-6 overflow-hidden rounded-lg shadow-xl cursor-default bg-darkGray ${className} max-h-[92.5%]  lg:max-h-[650px] overflow-y-auto`}>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

ModalWrapper.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
