// Component imports
import BarLoader from "@/UI/BarLoader";
import PanelIconMod from "@/UI/PanelIconMod";

// External Libraries
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// API Hooks
import { fetchPanelData } from "../panel/panelAPI";
import { fetchTasks } from "../tasks/taskAPI";

// Custom Hooks
import { isNavLinkActive } from "@/utils/utils";

// Store Imports
import { useIsPrivileged } from "@/hooks/hooks";
import { useDomainStore } from "../domain/domainStore";
import { usePanelStore } from "../panel/panelStore";
import { useTaskStore } from "../tasks/tasksStore";

export default function SidebarPanelContainer() {
  // Zustand States
  const { setAddPanel, panels } = usePanelStore((state) => state);

  const DomainLoading = useDomainStore((store) => store.loading);

  const { isPrivileged } = useIsPrivileged();

  return (
    <div>
      <div
        className={`flex  mb-2 flex-col ${
          panels?.length > 0 && "gap-3.5"
        }     small__heading text-mediumGray border-lines`}>
        {/*  */}
        <div className="relative h-full ">
          {DomainLoading && (
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full animate-pulse bg-[#a19fdd]">
              <BarLoader />
            </div>
          )}

          {panels?.length === 0 && (
            <div className="px-3 my-2 lg:px-7 py-[15px] font-regular">
              No Panels Yet
            </div>
          )}

          <div className=" max-h-[200px]  border-b border-lines   lg:h-[10%] overflow-y-scroll   ">
            {panels?.length >= 1 &&
              panels?.map((panel) => {
                const sanitizedPanelName = `${panel?.name
                  .toLowerCase()
                  .replace(/\s+/g, "-")
                  .replace(/\//g, "-")}`;

                return (
                  <PanelLinkItem
                    key={panel.id}
                    to={`panel/${sanitizedPanelName}/${panel.id}`}
                    text={panel.name}
                    panel={panel}
                    isActive={isNavLinkActive(panel.id)}
                  />
                );
              })}
          </div>
        </div>
      </div>

      {isPrivileged && (
        <div tabIndex="0" className="mr-3">
          <button
            className={`flex items-center w-full  font-bold gap-2 mr-3 my-2 text-primary cursor-pointer text-[14px]  md:text-md  rounded-tr-3xl rounded-br-3xl px-3  lg:px-7 hover:bg-primaryIdle py-[15px]`}
            disabled={DomainLoading}
            onClick={() => setAddPanel(true)}>
            <PanelIconMod fill="#635FC7" />

            <span className="text-[14px]">+ Create New Panel</span>
          </button>
        </div>
      )}
    </div>
  );
}

function PanelLinkItem({ to, text, isActive, panel }) {
  const activeClass = isActive
    ? "bg-primary text-white"
    : "hover:bg-bgHover hover:text-primary";
  const fillColor = isActive ? "#fff" : "#828FA3";

  const {
    setTasks,
    setLoading: setTaskLoading,
    setError: setTaskError,
    tasks,
    loading,
  } = useTaskStore((store) => store);

  const activeDomain = useDomainStore((store) => store.activeDomain);

  const {
    panels,
    setPanels,
    setActivePanel,
    setLoading: setPanelLoading,
  } = usePanelStore((store) => store);

  const currentPanelId = to.split("/").pop();

  const handleClick = async (panel) => {
    setTaskError(null);

    setActivePanel(panel);

    const data = {
      domainID: activeDomain.id,
      panelID: currentPanelId,
    };

    // Check if tasks for the current panel ID are already cached
    if (tasks[currentPanelId]) {
      setTasks(currentPanelId, tasks[currentPanelId]);
      return;
    }

    setTaskLoading(true);
    setPanelLoading(true);

    // Use Promise.all to run both fetch operations in parallel
    const [tasksResponse, panelMembersResponse] = await Promise.all([
      fetchTasks(activeDomain.id, currentPanelId),
      fetchPanelData(data),
    ]);

    if (!tasksResponse.success || !panelMembersResponse.success) {
      setTaskError(tasksResponse.message);
      setTaskLoading(false);
      setPanelLoading(false);
      return;
    }

    const { panel_data, panel_members } = panelMembersResponse.data;

    // Update the panels with its members
    const updatedPanels = panels.map((panel) => {
      if (panel.id === panel_data.id) {
        return {
          ...panel,
          panel_members: panel_members,
        };
      }
      return panel;
    });

    setPanels(updatedPanels);
    setActivePanel(updatedPanels.find((p) => p.id === panel_data.id));

    setTasks(currentPanelId, tasksResponse.data);

    setTaskError(null);
    setTaskLoading(false);
    setPanelLoading(false);
  };

  return (
    <NavLink to={to} role="link" className="">
      <button
        className={`flex transition-all  my-2  duration-300 font-semibold tracking-wide gap-2 items-center lg:gap-4 py-[12px] mr-3 max-w-[95%] cursor-pointer rounded-tr-3xl w-full rounded-br-3xl px-3  lg:px-7 ${activeClass}`}
        disabled={loading}
        onClick={() => handleClick(panel)}>
        <PanelIconMod fill={fillColor} />
        <span className="text-[14px] capitalize  ">{text}</span>
      </button>
    </NavLink>
  );
}

PanelLinkItem.propTypes = {
  to: PropTypes.string,
  panel: PropTypes.object,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};
