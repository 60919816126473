// Component imports
import Avatar from "@/UI/Avatar";
import Button from "@/UI/Button";
import { OnboardingPageWrapper } from "./Onboarding";

// Static imports
import DummyImage from "@/assets/avatar.svg";

// External Libraries
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { GoTrash } from "react-icons/go";
import { TbCameraPlus } from "react-icons/tb";

// Context
import { useOnboardingContext } from "./OnboardingContext";
import AddCameraIcon from "@/icons/AddCameraIcon";

export default function OnboardingProfile({ onBack, onNext, onboardingState }) {
  const {
    selectedImage,
    setSelectedImage,
    localSelectedImage,
    setLocalSelectedImage,
  } = useOnboardingContext();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);

    // Check if a file was selected
    if (!file) {
      setSelectedImage(selectedImage);
      setLocalSelectedImage(selectedImage);
      return;
    }

    // Check if the selected file is an image
    if (!file.type.startsWith("image/")) {
      toast.error("Please select a valid image file.");
      setSelectedImage(null);
      setLocalSelectedImage(null);
      return;
    }

    // Check if the file size is more than 3MB
    if (file.size > 3 * 1024 * 1024) {
      // 3MB in bytes
      toast.error("The selected image must not be larger than 3MB.");
      setLocalSelectedImage(null);
      setSelectedImage(null);
      return;
    }

    setSelectedImage(file);

    // If the file is valid, create a data URL and set it as the selected image
    const dataURL = URL.createObjectURL(file);
    console.log(dataURL);
    setLocalSelectedImage(dataURL);
  };

  return (
    <OnboardingPageWrapper
      className="flex flex-col gap-8 "
      key={onboardingState}>
      <div className="flex flex-col items-center ">
        <h2 className="text-3xl font-bold text-center text-bold ">
          You&apos;re almost done!
        </h2>

        <div className="mt-8 mb-2 transition-all duration-300 border-4 border-transparent rounded-full hover:border-primaryHover">
          <label
            className="relative rounded-full cursor-pointer "
            htmlFor="avatarInput">
            <Avatar
              width="10"
              className="w-full h-auto"
              imgSrc={localSelectedImage || DummyImage}
            />
            {!localSelectedImage && (
              <div
                className={`absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-full  bg-slate-900/50`}>
                {/* <img
                  src={addProfileImage}
                  alt=" Add Profile Image"
                  className=" max-w-8 aspect-square"
                /> */}
                <AddCameraIcon />
                <input
                  htmlFor="avatarInput"
                  id="avatarInput"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
            {localSelectedImage && (
              <>
                <div className="absolute bottom-0 left-0 flex items-center justify-center p-2 bg-purple-200 rounded-full hover:bg-primaryHover">
                  <TbCameraPlus size={16} color="#635fc7" className="z-20 " />
                </div>
                <div
                  className="absolute bottom-0 right-0 flex items-center justify-center p-2 rounded-full hover:bg-cautionHover bg-red-50 "
                  onClick={(e) => {
                    setSelectedImage(null);
                    setLocalSelectedImage(null);
                    e.preventDefault();
                  }}>
                  <GoTrash
                    size={16}
                    color="rgba(234, 85, 85, 1)"
                    className="z-20 "
                  />
                </div>
              </>
            )}

            <input
              htmlFor="avatarInput"
              id="avatarInput"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
        </div>

        <div className="space-y-[4px] mt-4 ">
          <h3 className=" text-[22px] font-bold text-center text-nowrap ">
            Setup Profile
          </h3>
          <p className="text-[14px] font-regular text-mediumGray">
            Click on the image to select your Avatar
          </p>
        </div>
        <div className="flex gap-4 mt-6">
          <Button
            variant="secondary"
            content="Back"
            className="px-8 py-2 w-max"
            onClick={onBack}
          />
          <Button
            content="Next"
            className="px-8 py-2 rounded-full w-max"
            onClick={onNext}
          />
        </div>
      </div>
    </OnboardingPageWrapper>
  );
}

OnboardingProfile.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onboardingState: PropTypes.number,
};
