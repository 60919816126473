// Static imports
import "./index.css";
import "flag-icon-css/css/flag-icons.min.css";

// Component imports
import App from "./App.jsx";

// External Libraries

import ReactDOM from "react-dom/client";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister().then((success) => {
          if (success) {
            return;
            // console.log("Service Worker unregistered:", registration);
          } else {
            console.error(
              "Service Worker unregistration failed:",
              registration
            );
          }
        });
      }
    });
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
