import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialAnnouncementsState = {
  announcements: [],
  loading: false,
  error: null,
};

export const useAnnouncementsStore = create(
  persist(
    (set) => ({
      ...initialAnnouncementsState,
      setAnnouncements: (announcements) => set({ announcements }),
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),
      reset: () => set(initialAnnouncementsState),
    }),
    { name: "announcements-storage" }
  )
);
