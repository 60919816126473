import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialAnnouncementNotificationSstate = {
  announcementNotifications: [],
  loading: false,
  error: null,
};

export const useAnnouncementNotificationsStore = create(
  persist(
    (set) => ({
      ...initialAnnouncementNotificationSstate,

      setAnnouncementNotifications: (announcementNotifications) =>
        set({ announcementNotifications }),

      setLoading: (loading) => set({ loading }),

      setError: (error) => set({ error }),

      reset: () => set(initialAnnouncementNotificationSstate),
    }),
    { name: "announcement-notifications-storage" }
  )
);

const initialTaskNotificationState = {
  taskNotifications: [],
  loading: false,
  error: null,
};

export const useTaskNotificationsStore = create(
  persist(
    (set) => ({
      ...initialTaskNotificationState,

      setTaskNotifications: (taskNotifications) => set({ taskNotifications }),

      setLoading: (loading) => set({ loading }),

      setError: (error) => set({ error }),

      reset: () => set(initialTaskNotificationState),
    }),
    { name: "task-notifications-storage" }
  )
);
