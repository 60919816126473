// Import your stores

import { useAccountStore } from "@/features/user/account/accountStore";
import { useAnnouncementsStore } from "@/features/announcements/announcementsStore";
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { useColumnStore } from "@/features/column/columnStore";
import { useDomainStore } from "@/features/domain/domainStore";
import { usePanelStore } from "@/features/panel/panelStore";
import { useProfileStore } from "@/features/user/profile/profileStore";
import { useSessionStore } from "@/UI/sessionsStore";
import { useTaskStore } from "@/features/tasks/tasksStore";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "@/features/notifications/notificationsStore";
import { useAnalyticsStore } from "@/features/analytics/analyticsStore";

// List of all stores
const stores = [
  useDomainStore,
  useProfileStore,
  usePanelStore,
  useColumnStore,
  useAccountStore,
  useTaskStore,
  useCollaboratorsStore,
  useAnnouncementsStore,
  useSessionStore,
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
  useAnalyticsStore,
];

// Function to reset all stores
function resetAllStores() {
  stores.forEach((store) => {
    store.getState().reset();
  });
}

// Function to clear localStorage and reset all stores
export async function clearBrowserStorage() {
  // Clear browser storage
  await Promise.all([
    localStorage.removeItem("kreed_at"),
    localStorage.removeItem("profile-storage"),
    localStorage.removeItem("domain-storage"),
    localStorage.removeItem("panel-storage"),
    localStorage.removeItem("tasks-storage"),
    localStorage.removeItem("status-storage"),
    localStorage.removeItem("account-storage"),
    localStorage.removeItem("collaborators-storage"),
    localStorage.removeItem("announcements-storage"),
    localStorage.removeItem("announcement-notifications-storage"),
    localStorage.removeItem("task-notifications-storage"),
    localStorage.removeItem("analytics-storage"),
  ]);

  // Reset all Zustand stores
  resetAllStores();
}
