// Component imports
import Button from "@/UI/Button";
import ModalWrapper from "@/UI/ModalWrapper";
import PanelIconMod from "@/UI/PanelIconMod";
import DeletePanel from "../panel/DeletePanel";
import SearchedTasks from "../tasks/TaskSearchContainer";

// External Libraries
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

import { BiSolidHide } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import {
  FaDownload,
  FaEye,
  FaPlus,
  FaTrash,
  FaUserMinus,
  FaUserPlus,
  FaUsers,
  FaXmark,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

// Store Imports
import { useColumnStore } from "../column/columnStore";
import { useDomainStore } from "../domain/domainStore";
import { useEditPanelStore } from "../panel/editPanelStore";
import {
  usePanelInviteStore,
  usePanelRemoveStore,
  usePanelStore,
} from "../panel/panelStore";
import { useAddTaskModalStore } from "../tasks/addTaskModalStore";
import { useTaskStore } from "../tasks/tasksStore";

// React imports
import { useRef, useState } from "react";

// Static imports
import KebabIcon from "@/assets/Kebab_menu.svg";

// Custom Hooks
import { useClickOutside, useIsPrivileged } from "@/hooks/hooks";
import { downloadCSV } from "@/utils/csvUtils";
import { isNavLinkActive } from "@/utils/utils";

// API Hooks
import { fetchPanelData } from "../panel/panelAPI";
import { fetchTasks } from "../tasks/taskAPI";

// Animation configs
import { kebabContentVariants } from "@/utils/framerAnimationConfigs";

import toast from "react-hot-toast";

export default function HeaderPanelDropdown({ open, setOpen }) {
  // Zustand States
  const {
    columns,
    setShowAllColumns,
    showAllColumns,
    loading: ColumnLoading,
  } = useColumnStore((state) => state);
  const { panels, setShowPanelMembers } = usePanelStore((store) => store);

  const setAddTaskModal = useAddTaskModalStore(
    (store) => store.setAddTaskModal
  );

  const setEditPanel = useEditPanelStore((store) => store.setEditPanel);

  const setInvitePanel = usePanelInviteStore((store) => store.setInvitePanel);
  // Zustand States
  const setRemovePanel = usePanelRemoveStore((store) => store.setRemovePanel);

  const activePanel = usePanelStore((store) => store.activePanel);

  const tasks = useTaskStore((store) => store.tasks);

  const { isPrivileged } = useIsPrivileged();

  // React State  Refs
  const [isDeletePanel, setIsDeletePanel] = useState(false);

  const panelDropdownRef = useRef(null);

  // Click outside handler
  useClickOutside(panelDropdownRef, () => setOpen(false));

  // Selecting the current tasks via the panel ID
  const activePanelTasks = tasks[activePanel?.id] || [];

  return (
    <>
      <SearchedTasks />
      {columns?.length > 0 && columns !== undefined && (
        <>
          <div className="block mr-6 md:hidden">
            <Button
              variant="add"
              content=""
              onClick={() => {
                setAddTaskModal(true);
              }}
              className="hidden  text-[20px]  max-[1200px]:mr-6 md:block"
              disabled={ColumnLoading}
            />
          </div>
          <div className="hidden md:block">
            <Button
              variant="add"
              content="Add New Task"
              onClick={() => {
                setAddTaskModal(true);
              }}
              disabled={ColumnLoading}
              className="hidden  max-[1200px]:mr-8 md:block"
            />
          </div>
        </>
      )}

      {
        <div
          className="relative flex items-center justify-center "
          ref={panelDropdownRef}>
          <button
            onClick={() => setOpen((prev) => !prev)}
            className="flex items-center justify-center w-8 p-2 rounded-full aspect-square sm:px-4 hover:bg-veryDarkGray">
            {open ? (
              <FaXmark className=" min-w-6" color="rgba(152, 170, 179, 1)" />
            ) : (
              <img className="block min-w-1" src={KebabIcon} alt="Kebab Icon" />
            )}
          </button>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={kebabContentVariants.initial}
                animate={kebabContentVariants.animate}
                exit={kebabContentVariants.exit}
                key={open}
                className="absolute font-medium rounded-md border bg-veryDarkGray border-lines  text-mediumGray shadow-xl flex flex-col gap-2 p-2 top-12 right-0 z-[100] w-max min-w-52 text-sm ">
                <div className="flex flex-col pb-2 border-b-2 sm:hidden border-lines">
                  <h2 className="px-2  py-1 text-[10px] tracking-widest font-bold uppercase text-textColor  w-fit rounded-md mb-1">
                    Panels ({panels?.length})
                  </h2>
                  <div className="flex flex-col gap-2">
                    {panels?.length >= 1 &&
                      panels?.map((panel) => {
                        const panelUrl = `${panel?.name
                          .toLowerCase()
                          .replace(/\s+/g, "-")
                          .replace(/\//g, "-")}`;

                        return (
                          <HeaderLink
                            key={panel.id}
                            to={`panel/${panelUrl}/${panel.id}`}
                            isActive={isNavLinkActive(panel.id)}
                            panel={panel}
                            setOpen={setOpen}
                          />
                        );
                      })}
                  </div>
                  {isPrivileged && (
                    <span className="flex items-center gap-2 p-2 mt-2 rounded-md text-mediumGray bg-primaryIdleHover">
                      <FaPlus size={16} /> <span> Create New Panel</span>
                    </span>
                  )}
                </div>
                {isPrivileged && (
                  <HeaderDropdownButton
                    onClick={() => {
                      setOpen(false);
                      setEditPanel(true);
                    }}>
                    <FaEdit size={16} />
                    <span>Edit Panel</span>
                  </HeaderDropdownButton>
                )}

                <HeaderDropdownButton
                  onClick={() => {
                    setOpen(false);
                    setShowAllColumns(!showAllColumns);
                  }}>
                  {showAllColumns ? (
                    <BiSolidHide size={16} />
                  ) : (
                    <FaEye size={16} />
                  )}

                  <span>
                    {showAllColumns
                      ? "Hide Empty Columns"
                      : "Show Empty Columns"}
                  </span>
                </HeaderDropdownButton>

                <HeaderDropdownButton
                  onClick={() => {
                    setOpen(false);
                    setShowPanelMembers(true);
                  }}>
                  <FaUsers size={16} />
                  <span>See Panel Members</span>
                </HeaderDropdownButton>

                {isPrivileged && (
                  <HeaderDropdownButton
                    onClick={() => {
                      setOpen(false);
                      setInvitePanel(true);
                    }}
                    className=" text-colorCreative hover:text-activeUser">
                    <FaUserPlus size={16} />
                    <span> Invite Members</span>
                  </HeaderDropdownButton>
                )}

                {isPrivileged && (
                  <HeaderDropdownButton
                    className="text-cautionHover hover:!text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setRemovePanel(true);
                    }}>
                    <FaUserMinus size={16} />
                    <span> Remove Members</span>
                  </HeaderDropdownButton>
                )}

                <HeaderDropdownButton
                  onClick={() => {
                    activePanelTasks.length > 0
                      ? downloadCSV(activePanelTasks, activePanel.name, columns)
                      : toast.error("No task data to download");
                  }}>
                  <FaDownload size={16} />
                  <span> Download as CSV</span>
                </HeaderDropdownButton>

                {isPrivileged && (
                  <HeaderDropdownButton
                    className="text-cautionHover hover:!text-red-500"
                    onClick={() => {
                      setOpen(false);
                      setIsDeletePanel(true);
                    }}>
                    <FaTrash size={16} />
                    <span> Delete Panel</span>
                  </HeaderDropdownButton>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      }

      <ModalWrapper isOpen={isDeletePanel} onClose={setIsDeletePanel}>
        <DeletePanel close={setIsDeletePanel} />
      </ModalWrapper>
    </>
  );
}

const HeaderDropdownButton = ({ children, className, onClick }) => (
  <button
    className={`flex text-[13px]  items-center hover:text-white  gap-4 p-2 transition-all duration-300 rounded-md cursor-pointer  hover:bg-darkGray  ${className}`}
    onClick={onClick}>
    {children}
  </button>
);

function HeaderLink({ to, isActive, panel, setOpen }) {
  // Zustand States
  const { panels, setPanels, setActivePanel } = usePanelStore((store) => store);

  const {
    setTasks,
    setLoading: setTaskLoading,
    setError: setTaskError,
    tasks,
  } = useTaskStore((store) => store);

  const activeDomain = useDomainStore((store) => store.activeDomain);

  const currentPanelId = to.split("/").pop();

  const handleClick = async (panel) => {
    setOpen((prev) => !prev);
    setTaskError(null);
    setActivePanel(panel);

    const data = {
      domainID: activeDomain.id,
      panelID: currentPanelId,
    };

    if (tasks[currentPanelId]) {
      setTasks(currentPanelId, tasks[currentPanelId]);
      return;
    }
    setTaskLoading(true);

    // Fetch tasks and panel data in parallel
    const [taskResponse, panelMembersResponse] = await Promise.all([
      fetchTasks(activeDomain.id, currentPanelId),
      fetchPanelData(data),
    ]);

    if (!taskResponse.success || !panelMembersResponse.success) {
      setTaskError(taskResponse.message);
      setTaskLoading(false);
      return;
    }

    // Update the state with the fetched data
    const { panel_data, panel_members } = panelMembersResponse.data;

    // Update the panels with its members
    const updatedPanels = panels.map((panel) => {
      if (panel.id === panel_data.id) {
        return {
          ...panel,
          panel_members: panel_members,
        };
      }
      return panel;
    });

    setPanels(updatedPanels);
    setTasks(currentPanelId, taskResponse.data);
    setTaskLoading(false);
  };

  return (
    <Link
      to={to}
      onClick={() => handleClick(panel)}
      className={`flex gap-2 p-2 font-medium hover:bg-bgHover  hover:text-primary text-sm capitalize rounded-md cursor-pointer text-mediumGray ${
        isActive && " bg-primary text-textColor"
      } `}>
      <PanelIconMod fill={isActive ? "#fff" : "#828FA3"} />
      {panel.name}
    </Link>
  );
}

HeaderLink.propTypes = {
  to: PropTypes.string,
  isActive: PropTypes.bool,
  panel: PropTypes.object,
  setOpen: PropTypes.func,
};

HeaderPanelDropdown.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenHeaderSearch: PropTypes.func,
  openHeaderSearch: PropTypes.bool,
};

HeaderDropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
