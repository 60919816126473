// External Libraries
import PropTypes from "prop-types";

const PanelIconMod = ({ fill = "#828FA3", width = "16", height = "17" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.846133 1.07025C0.304363 1.61203 0 2.34683 0 3.11301V13.3353C0 14.1015 0.304363 14.8362 0.846133 15.3779C1.3879 15.9198 2.12271 16.2241 2.88889 16.2241H13.1111C13.8773 16.2241 14.6121 15.9198 15.1538 15.3779C15.6957 14.8362 16 14.1015 16 13.3353V3.11301C16 2.34683 15.6957 1.61203 15.1538 1.07025C14.6121 0.528484 13.8773 0.224121 13.1111 0.224121H2.88889C2.12271 0.224121 1.3879 0.528484 0.846133 1.07025ZM1.33333 13.3353V8.6686H9.77781V14.8908H2.88889C2.03022 14.8908 1.33333 14.1939 1.33333 13.3353ZM9.77781 7.33524V1.55745H2.88889C2.47633 1.55745 2.08067 1.72135 1.78895 2.01307C1.49723 2.30479 1.33333 2.70045 1.33333 3.11301V7.33524H9.77781ZM11.1111 6.0019H14.6667V10.4463H11.1111V6.0019ZM14.6667 11.7796H11.1111V14.8908H13.1111C13.5236 14.8908 13.9194 14.7269 14.2111 14.4352C14.5028 14.1435 14.6667 13.7477 14.6667 13.3353V11.7796ZM14.6667 3.11301V4.66857H11.1111V1.55745H13.1111C13.5236 1.55745 13.9194 1.72135 14.2111 2.01307C14.5028 2.30479 14.6667 2.70045 14.6667 3.11301Z"
        fill={fill}
      />
    </svg>
  );
};

export default PanelIconMod;

PanelIconMod.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
