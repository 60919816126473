import { create } from "zustand";

const initialAddTaskState = {
  isAddTaskModalOpen: false,
  status: "",
  assignModal: false,
  assigned: ["David", "Charles"],
  subTasksData: [],
};
export const useAddTaskModalStore = create((set) => ({
  ...initialAddTaskState,

  setStatus: (status) => set({ status }),

  setAssignModal: (assignModal) => set({ assignModal }),

  setAssigned: (assigned) => set({ assigned }),

  setSubTasksData: (subTasksData) => set({ subTasksData }),

  setAddTaskModal: (isAddTaskModalOpen) => set({ isAddTaskModalOpen }),
}));
