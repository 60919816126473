export default function AddCameraIcon() {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5734 9.74901H33.654L30.3244 6.04941H19.2256V9.74901H28.6596L32.1742 13.4486H39.5734V35.6462H9.97655V18.998H6.27695V35.6462C6.27695 37.681 7.94177 39.3458 9.97655 39.3458H39.5734C41.6082 39.3458 43.273 37.681 43.273 35.6462V13.4486C43.273 11.4138 41.6082 9.74901 39.5734 9.74901ZM15.526 24.5474C15.526 32.779 25.4964 36.9226 31.3233 31.0957C37.1502 25.2689 33.0066 15.2984 24.775 15.2984C19.6695 15.2984 15.526 19.442 15.526 24.5474ZM24.775 18.998C26.2335 19.0399 27.6208 19.638 28.6526 20.6698C29.6844 21.7016 30.2825 23.0889 30.3244 24.5474C30.2825 26.006 29.6844 27.3933 28.6526 28.425C27.6208 29.4568 26.2335 30.0549 24.775 30.0968C23.3164 30.0549 21.9291 29.4568 20.8974 28.425C19.8656 27.3933 19.2675 26.006 19.2256 24.5474C19.2675 23.0889 19.8656 21.7016 20.8974 20.6698C21.9291 19.638 23.3164 19.0399 24.775 18.998ZM9.97655 9.74901H15.526V6.04941H9.97655V0.5H6.27695V6.04941H0.727539V9.74901H6.27695V15.2984H9.97655"
        fill="white"
      />
    </svg>
  );
}
