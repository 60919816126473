import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialTaskState = {
  tasks: [],
  loading: false,
  error: null,
};

export const useTaskStore = create(
  persist(
    (set) => ({
      ...initialTaskState,

      setTasks: (panelId, tasks) =>
        set((state) => ({
          tasks: {
            ...state.tasks,
            [panelId.toString()]: tasks,
          },
        })),

      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),

      // Reset tasks for all panels
      resetTasks: () => set({ tasks: {} }), // Clear the entire tasks object

      reset: () => set(initialTaskState),
    }),
    { name: "tasks-storage" }
  )
);
