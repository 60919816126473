import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useOnboardingStore = create(
  persist(
    (set) => ({
      isOnboarding: JSON.parse(localStorage.getItem("isOnboarding")) || false,
      setIsOnboarding: (isOnboarding) => set({ isOnboarding }),
    }),
    { name: "onboarding-storage" }
  )
);
