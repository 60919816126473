import Button from "@/UI/Button";
import { MAX_FORM_INPUT_LENGTH } from "@/utils/constants";
import { isValidFormValue } from "@/utils/utils";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaXmark } from "react-icons/fa6";
import { editDomain } from "./domainAPI";
import { useDomainStore, useEditDomainStore } from "./domainStore";

export default function EditDomain() {
  const {
    setEditDomain,
    curDomain,
    loading: DomainLoading,
  } = useEditDomainStore((store) => store);

  const { domains, setDomains, setActiveDomain } = useDomainStore(
    (store) => store
  );

  const [localName, setLocalName] = useState(curDomain.name);

  const { setLoading: setDomainLoading } = useDomainStore((store) => store);

  const handleEditDomain = async (e) => {
    e.preventDefault();

    if (!isValidFormValue(localName)) {
      toast.error(
        "Domain name has to be longer than 2 letters excluding spaces"
      );
      return;
    }
    setDomainLoading(true);

    const prevDomains = domains;

    // Optimistic Edit
    const updatedDomains = domains.map((col) =>
      col.id === curDomain.id ? { ...col, name: localName } : col
    );

    setActiveDomain(updatedDomains.find((d) => d.id === curDomain.id));

    setDomains(updatedDomains);
    setEditDomain(false);

    const data = {
      domainName: localName,
      domainID: curDomain.id,
    };

    // Edit status
    const editResult = await editDomain(data);

    if (!editResult.success) {
      setDomainLoading(false);
      // Revert to previous state if editing fails
      setDomains(prevDomains);
      toast.error(editResult.message);
      return;
    }
    setDomainLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_FORM_INPUT_LENGTH) setLocalName(value);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between ">
        <h1 className="modal__header">Edit Domain</h1>
        <button
          onClick={() => setEditDomain(false)}
          className="p-2 rounded-full cursor-pointer hover:bg-veryDarkGray">
          <FaXmark size={20} color=" rgba(130, 143, 163, 1)" />
        </button>
      </div>
      <form onSubmit={handleEditDomain} className="flex flex-col gap-4">
        <label htmlFor="panelName" className="relative flex flex-col space-y-2">
          <span className="block mb-0 modal__labels ">Domain Name</span>
          <input
            type="text"
            onChange={handleChange}
            value={localName}
            className="capitalize input__style"
          />
          <p className="absolute text-sm font-light -top-2 right-1 text-mediumGray">
            {localName.length} / {MAX_FORM_INPUT_LENGTH}
          </p>
        </label>
        <div className="flex flex-col gap-4 ">
          <span className="mt-4 ">
            <Button content="Save Changes" disabled={DomainLoading} />
          </span>
        </div>
      </form>
    </div>
  );
}
