// External Libraries
import PropTypes from "prop-types";

// React Hooks
import { createContext, useCallback, useContext, useMemo } from "react";

const AnnouncementContext = createContext();

// Custom hook with a guard clause
export const useAnnouncementContext = () => {
  const context = useContext(AnnouncementContext);
  if (!context) {
    throw new Error(
      "useAnnouncementContext must be used within an AnnouncementProvider"
    );
  }
  return context;
};

export const AnnouncementProvider = ({ children }) => {
  const scrollToAnnouncement = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });

      // Apply temporary border to the first child element inside
      const firstChild = element.firstElementChild;
      if (firstChild) {
        firstChild.style.border = "2px solid #635fc7";

        setTimeout(() => {
          firstChild.style.border = "2px solid transparent"; // Remove border after 5 seconds
        }, 5000);
      }
    }
  }, []);

  const contextValue = useMemo(() => {
    return { scrollToAnnouncement };
  }, [scrollToAnnouncement]);

  return (
    <AnnouncementContext.Provider value={contextValue}>
      {children}
    </AnnouncementContext.Provider>
  );
};

AnnouncementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
