// Component imports
import Button from "./Button";

// External Libraries
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div
      className={` bg-veryDarkGray h-[100dvh] text-textColor flex justify-center items-center flex-col text-center gap-4`}>
      <div>
        <h1 className="text-2xl sm:text-4xl lg:text-5xl"> 404 Error</h1>
        <p className="text-2xl ">Page not found!</p>
      </div>
      <Button
        variant="white"
        content="Back to Home"
        onClick={() => navigate("/home")}
        className="px-4 py-2 w-max"
      />
    </div>
  );
}
