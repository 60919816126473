import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialColumnState = {
  loading: false,
  error: null,
  isAddColumnOpen: false,
  columns: [],
  showAllColumns: true,
};

export const useColumnStore = create(
  persist(
    (set) => ({
      ...initialColumnState,
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),
      setAddColumn: (isAddColumnOpen) => set({ isAddColumnOpen }),
      setColumns: (columns) => {
        const sortedColumns = [...columns].sort((a, b) => {
          if (a.name === "completed") return 1;
          if (b.name === "completed") return -1;
          return 0;
        });
        set({ columns: sortedColumns });
      },
      setShowAllColumns: (showAllColumns) => set({ showAllColumns }),
      reset: () => set(initialColumnState),
    }),
    { name: "status-storage" }
  )
);

export const useEditColumnStore = create((set) => ({
  isEditColumnOpen: false,
  loading: false,
  error: null,
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setEditColumn: (isEditColumnOpen) => set({ isEditColumnOpen }),
}));
