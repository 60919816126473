// External Libraries
import { AnimatePresence, motion } from "framer-motion";
import { Clock } from "lucide-react";
import { FiCheck } from "react-icons/fi";
import PropTypes from "prop-types";

// React imports
import { createContext, useContext, useMemo, useRef, useState } from "react";

// Custom Hooks
import { convertToISODateTime } from "@/utils/dateAndTimeUtils";
import { useClickOutside } from "@/hooks/hooks";

const TimePickerContext = createContext();

const TimePicker = ({ children, fromTime, toTime, onChange }) => {
  const [openDropdown, setOpenDropdown] = useState({ from: false, to: false });

  const contextValue = useMemo(() => {
    const toggleDropdown = (type) => {
      setOpenDropdown((prev) => ({
        ...prev,
        [type]: !prev[type],
      }));
    };

    return {
      fromTime,
      toTime,
      onChange,
      openDropdown,
      toggleDropdown,
      setOpenDropdown,
    };
  }, [fromTime, toTime, onChange, openDropdown, setOpenDropdown]);

  return (
    <TimePickerContext.Provider value={contextValue}>
      <div className="flex flex-col w-full gap-4 sm:flex-row">{children}</div>
    </TimePickerContext.Provider>
  );
};

const TimeSelector = ({ type }) => {
  const {
    fromTime,
    toTime,
    onChange,
    openDropdown,
    toggleDropdown,
    setOpenDropdown,
  } = useContext(TimePickerContext);

  const selectedTime = type === "from" ? fromTime : toTime;

  const hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const periods = ["AM", "PM"];

  const handleChange = (timePart, value) => {
    const newTime = { ...selectedTime, [timePart]: value };

    onChange(type, convertToISODateTime(newTime));

    // Close the dropdown when the period is selected
    if (timePart === "period") toggleDropdown(type);
  };

  const renderListItem = (timePart, value) => {
    const isSelected = selectedTime[timePart] === value;
    return (
      <li
        key={value}
        className={`p-1 rounded-sm text-[14px] cursor-pointer flex justify-between items-center hover:bg-darkGray ${
          isSelected ? "bg-darkGray" : ""
        }`}
        onClick={() => handleChange(timePart, value)}>
        <span>{value}</span>
        {isSelected && <FiCheck />}
      </li>
    );
  };

  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => {
    setOpenDropdown((prev) => ({
      ...prev,
      [type]: false,
    }));
  });

  return (
    <div className="relative w-full timepicker">
      <label className="block mb-2 text-sm capitalize font-regular text-mediumGray">
        {type}
      </label>
      <div
        className="px-4 py-2 flex items-center justify-between w-full rounded border border-[#828FA340] hover:border-primary cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown(type);
        }}>
        <p className="text-[14px]">{`${selectedTime?.hours}:${selectedTime?.minutes} ${selectedTime?.period}`}</p>
        <span className="block">
          <Clock color="#635FC7" size={16} />
        </span>
      </div>

      <AnimatePresence>
        {openDropdown[type] && (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            key={openDropdown[type]}
            className="absolute w-full z-10 left-0 mt-2 grid gap-2 p-2 rounded-lg bg-veryDarkGray text-mediumGray top-[60px] max-h-[200px] overflow-y-scroll">
            <div className="flex gap-2" ref={dropdownRef}>
              <ul className="flex-1 relative  flex flex-col gap-1 max-h-[200px] overflow-y-scroll">
                <h3 className="sticky top-0 left-0 border-b bg-veryDarkGray border-mediumGray">
                  {" "}
                  Hours
                </h3>
                {hours.map((hour) => renderListItem("hours", hour))}
              </ul>
              <ul className="flex-1 flex relative flex-col gap-1 max-h-[200px] overflow-y-scroll">
                <h3 className="sticky top-0 left-0 border-b bg-veryDarkGray border-mediumGray">
                  {" "}
                  Minutes
                </h3>
                {minutes.map((minute) => renderListItem("minutes", minute))}
              </ul>
              <ul className="flex-1 relative flex flex-col gap-1 max-h-[200px] overflow-y-scroll">
                <h3 className="sticky top-0 left-0 border-b bg-veryDarkGray border-mediumGray">
                  {" "}
                  Period
                </h3>
                {periods.map((period) => renderListItem("period", period))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

TimePicker.TimeSelector = TimeSelector;

TimePicker.propTypes = {
  children: PropTypes.node,
  fromTime: PropTypes.shape({
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
  }).isRequired,
  toTime: PropTypes.shape({
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

TimeSelector.propTypes = {
  type: PropTypes.oneOf(["from", "to"]).isRequired,
};

export default TimePicker;
