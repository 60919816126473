import Avatar from "@/UI/Avatar";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useViewProfileStore } from "../user/profile/profileStore";
// Static imports
import NoCollaborator from "@/assets/no_user.svg";
import { handleCollaboratorClick } from "@/utils/mentionUtils";
import { useNavigate } from "react-router-dom";
import { useCollaboratorsStore } from "./collaboratorsStore";

export default function CollaboratorSearchResults({
  searched,
  foundResults,
  clearSearch,
}) {
  const setProfileId = useViewProfileStore((store) => store.setProfileId);

  const collaborators = useCollaboratorsStore((store) => store.collaborators);

  const navigate = useNavigate();

  const handleUserClick = (userId) => {
    handleCollaboratorClick(collaborators, userId, setProfileId, navigate);
  };

  return (
    <AnimatePresence>
      {foundResults.length > 0 && searched && (
        <div className="absolute  left-0 w-full translate-y-full -bottom-1.5">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-col h-full gap-2 p-2 pt-0 overflow-y-auto border border-t-0 rounded-md rounded-tl-none rounded-tr-none max-h-96 bg-veryDarkGray border-lines">
            <h2 className="sticky top-0 p-2 font-bold bg-veryDarkGray">
              Collaborators ({foundResults.length})
            </h2>
            {foundResults.map((c) => (
              <div
                key={c.id}
                className="flex gap-2 p-4 break-words rounded-md hover:bg-primaryIdle lg:flex-row bg-darkGray ">
                <Avatar
                  width="3"
                  className="max-[1100px]:!w-9"
                  imgSrc={c.user.profilePicture}
                />
                <div className="flex flex-col justify-center max-[1100px]:gap-2 gap-1 text-[14px] font-semibold">
                  <div className="gap-1 max-[1100px]:text-[14px] flex max-[1100px]:flex-col ">
                    <span className="text-white">{c.user?.fullName}</span>
                    <button
                      className="cursor-pointer hover:text-primaryHover text-primary"
                      onClick={() => {
                        handleUserClick(c.user.id);
                        clearSearch();
                      }}>
                      @{c.user.username}
                    </button>
                  </div>
                  <p className="text-mediumGray max-[1100px]:hidden">
                    {c.user.jobTitle}
                  </p>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      )}
      {/* Show "No collaborators found" if search has been attempted and no results */}

      {searched && foundResults.length === 0 && (
        <div className="absolute left-0 w-full rounded-tr-none text-center -bottom-1.5 bg-veryDarkGray translate-y-full flex flex-col justify-center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="p-2 space-y-2 border border-t-0 rounded-md rounded-tl-none rounded-tr-none border-lines">
            <div className="">
              <img
                src={NoCollaborator}
                alt="No collaborator"
                className="mx-auto"
              />
            </div>
            <h2 className="text-lg font-bold">
              We couldn&apos;t find any collaborator with that name.{" "}
            </h2>
            <p className="text-[14px] text-mediumGray">
              Try refining your search terms or double-check for any typos.
            </p>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

CollaboratorSearchResults.propTypes = {
  searched: PropTypes.bool.isRequired,
  foundResults: PropTypes.arrayOf(String),
  clearSearch: PropTypes.func.isRequired,
};
