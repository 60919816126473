// DROPDOWN ANIMATIONS
export const dropdownVariants = {
  initial: { y: "50%", opacity: 0 },
  animate: {
    y: "0%",
    opacity: 1,
  },

  exit: { y: "-15%", opacity: 0 },
};

// Kebab Content
export const kebabContentVariants = {
  initial: {
    x: "-15%",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: { x: "-50%", opacity: 0 },
};

export const slideInVariants = {
  initial: { y: 20, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
};

export const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
    },
  },
};

export const itemVariants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    x: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

export const actionIconVariants = {
  open: { scale: 1, x: 0 },
  closed: { scale: 0, x: -7 },
};
