import { create } from "zustand";

export const useEditPanelStore = create((set) => ({
  isEditPanelOpen: false,
  loading: false,
  error: null,
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setEditPanel: (isEditPanelOpen) => set({ isEditPanelOpen }),
}));
