import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function generateCollabLink(data) {
  const { domainId, role } = data;

  const config = {
    method: "post",
    url: `/collaborators/create-link`,
    data: {
      domainId,
      role,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function joinDomain(data) {
  const config = {
    method: "post",
    url: `/collaborators/join-through-link`,
    data: data,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function changeCollaboratorRole(asyncData) {
  const socket = getSocket();
  const { domainID, userToBeModifiedId, action } = asyncData;
  const config = {
    method: "patch",
    url: `/collaborators/${domainID}/role-change`,
    data: {
      action: action,
      userToBeModifiedId: userToBeModifiedId,
    },
  };

  const { data, error } = await apiRequestWithTimeout(config);

  socket.emit("send-collab-announcement", {
    domain: domainID,
    message: "User role modified",
  });

  if (error) return handleApiError(error);

  return { success: true, data };
}

export async function removeCollaborator(asyncData) {
  const socket = getSocket();
  const { domainID, userToBeRemovedId } = asyncData;
  const config = {
    method: "patch",
    url: `/collaborators/${domainID}/remove-collaborator`,
    data: { userToBeRemovedId },
  };

  const { data, error } = await apiRequestWithTimeout(config);

  socket.emit("send-collab-announcement", {
    domain: domainID,
    message: "User exited the domain",
  });

  if (error) return handleApiError(error);

  return { success: true, data };
}

export async function fetchCollaborators(domainID) {
  const config = {
    method: "get",
    url: `/collaborators/${domainID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}
