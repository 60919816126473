import { AnimatePresence, motion } from "framer-motion";
import { useViewTaskStore } from "./viewTaskStore";
// Static Imports
import No_Result from "@/assets/No_result.svg";
import { useColumnStore } from "../column/columnStore";

import PropTypes from "prop-types";
export default function TaskSearchResult({
  foundResults,
  searched,
  clearSearch,
}) {
  const { setSelectedTask, setIsViewTaskOpen } = useViewTaskStore(
    (state) => state
  );

  const columns = useColumnStore((store) => store.columns);

  return (
    <AnimatePresence>
      {foundResults.length > 0 && searched && (
        <div className=" absolute left-0 w-full translate-y-full -bottom-1.5">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-col h-full gap-2 p-2 pt-0 overflow-y-auto border border-t-0 rounded-md rounded-tl-none rounded-tr-none max-h-96 bg-veryDarkGray border-lines">
            <h2 className="sticky top-0 p-2 font-bold bg-veryDarkGray">
              Tasks ({foundResults.length})
            </h2>
            {foundResults.map((task) => {
              const { statusId, subTasks, title, id: taskID } = task;
              const statusName = columns.find((c) => c.id === statusId).name;

              const doneSubtasks = subTasks.filter((s) => s.done).length;

              return (
                <div
                  key={taskID}
                  className="flex items-center justify-between gap-2 p-4 break-words rounded-md cursor-pointer bg-darkGray hover:bg-primaryIdle"
                  onClick={() => {
                    setSelectedTask(task);
                    setIsViewTaskOpen(true);
                    clearSearch();
                  }}>
                  <div className="flex flex-col gap-2 ">
                    <h2 className="leading-5 small__heading">{title}</h2>
                    <p className="text-sm text-mediumGray">
                      {doneSubtasks} of {subTasks.length} subtasks done
                    </p>
                  </div>
                  <p className="p-2 rounded-md min-w-fit bg-veryDarkGray">
                    {statusName.length > 10
                      ? statusName.slice(0, 10) + "..."
                      : statusName}
                  </p>
                </div>
              );
            })}
          </motion.div>
        </div>
      )}
      {/* Show "No collaborators found" if search has been attempted and no results */}
      {searched && foundResults.length === 0 && (
        <div className="absolute left-0 w-full rounded-tr-none text-center -bottom-1.5 bg-veryDarkGray translate-y-full flex flex-col justify-center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="p-2 space-y-2 border border-t-0 rounded-md rounded-tl-none rounded-tr-none border-lines">
            <div>
              <img src={No_Result} alt="No_Result SVG" className="mx-auto" />
            </div>
            <h2 className="text-lg font-bold">
              We couldn&apos;t find any task with that title
            </h2>
            <p className="text-[14px] text-mediumGray">
              Try refining your search terms or double-check for any typos.
            </p>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

TaskSearchResult.propTypes = {
  searched: PropTypes.bool.isRequired,
  foundResults: PropTypes.arrayOf(String),
  clearSearch: PropTypes.func.isRequired,
};
