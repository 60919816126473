import ProfileImage from "@/assets/avatar.webp";
import { create } from "zustand";

const initialAvatarStore = {
  controls: false,
  showAvatar: false,
  currentAvatar: ProfileImage,
  error: null,
  selectedAvatar: null,
  loading: false,
};
export const useAvatarStore = create((set) => ({
  ...initialAvatarStore,
  setControls: (controls) => set({ controls }),
  setShowAvatar: (showAvatar) => set({ showAvatar }),
  setCurrentAvatar: (currentAvatar) => set({ currentAvatar }),
  setError: (error) => set({ error }),
  setSelectedAvatar: (selectedAvatar) => set({ selectedAvatar }),
  setLoading: (loading) => set({ loading }),
}));
