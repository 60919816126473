import { useAnalyticsStore } from "@/features/analytics/analyticsStore";

export function isValidFormValue(input, charValue = 3) {
  return input.replace(/\s+/g, "").length >= charValue;
}

export function sanitizeTextForUrl(name) {
  return name
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]/g, "-"); // Replace non-alphanumeric characters (except hyphens) with hyphens
}

export function isNavLinkActive(panelId) {
  const currentPath = location.pathname;

  const pathParts = currentPath.split("/");
  const lastSegment = pathParts[pathParts.length - 1];
  const extractedPanelId = lastSegment.split("/").pop();

  // Check if the extracted ID matches the panelId
  return extractedPanelId === String(panelId);
}

// src/utils/colorUtils.js

// Function to convert hex to RGB
export const hexToRgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

// Function to convert RGB to hex
export const rgbToHex = (r, g, b) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Function to darken a color by a percentage
export const darkenColor = (color, percent) => {
  const [r, g, b] = hexToRgb(color);
  const factor = (100 - percent) / 100;
  const darkR = Math.round(r * factor);
  const darkG = Math.round(g * factor);
  const darkB = Math.round(b * factor);
  return rgbToHex(darkR, darkG, darkB);
};

// Function to extract the first color from the gradient
export const extractFirstColorFromGradient = (gradient) => {
  const colors = gradient.match(/#\w{6}/g);
  return colors ? colors[0] : "#000000"; // Default color if extraction fails
};

export const formatAndStoreAnalytics = (result, key, type) => {
  const dayMap = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // Format the result
  const formattedResult = Object.keys(result)?.map((day) => ({
    name: dayMap[day] || day,
    pv: parseFloat(result[day].toFixed(2)), // Truncate to 2 decimal places
  }));

  // Access the analytics store state
  const { analytics, setAnalytics } = useAnalyticsStore.getState();

  // Merge the new data with the existing analytics state
  const updatedAnalytics = {
    ...analytics, // Preserve existing analytics
    [type]: {
      ...analytics[type], // Preserve existing data under the specific type (if any)
      [key]: formattedResult, // Add/replace the new key's data
    },
  };

  // Update the analytics store with the new data
  setAnalytics(updatedAnalytics);

  return formattedResult;
};
