import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialProfileState = {
  userData: {
    fullName: "",
    username: "",
    jobTitle: "",
    department: "",
    location: "",
    profilePicture: "",
    language: "",
    availableHoursFrom: "",
    availableHoursTo: "",
  },
};

export const useProfileStore = create(
  persist(
    (set) => ({
      ...initialProfileState,
      setUserData: (userData) => set({ userData }),
      reset: () => set(initialProfileState),
    }),
    { name: "profile-storage" }
  )
);
export const useViewProfileStore = create((set) => ({
  currentProfileId: null,
  setProfileId: (currentProfileId) => set({ currentProfileId }),
}));
