import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialDomainState = {
  domains: [],
  activeDomain: {},
  loading: false,
  error: null,
  isAddDomainOpen: false,
};

export const useDomainStore = create(
  persist(
    (set) => ({
      ...initialDomainState,
      setDomains: (domains) => {
        // Convert input to an array if it's not already
        const domainArray = Array.isArray(domains) ? domains : [domains];
        // Use a Set to filter out duplicates
        const uniqueDomains = Array.from(new Set(domainArray));
        // Update the state with the unique domains
        set({ domains: uniqueDomains });
      },
      setActiveDomain: (activeDomain) => set({ activeDomain }),
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),
      setAddDomain: (isAddDomainOpen) => set({ isAddDomainOpen }),
      reset: () => set(initialDomainState),
    }),
    { name: "domain-storage" }
  )
);

export const useEditDomainStore = create((set) => ({
  isEditDomainOpen: false,
  isDeleteDomainOpen: false,
  curDomain: {},
  loading: false,
  error: null,
  setLoading: (loading) => set({ loading }),
  setCurDomain: (curDomain) => set({ curDomain }),
  setError: (error) => set({ error }),
  setEditDomain: (isEditDomainOpen) => set({ isEditDomainOpen }),
  setDeleteDomain: (isDeleteDomainOpen) => set({ isDeleteDomainOpen }),
}));
