import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

// import { deleteToken, getToken } from "firebase/messaging";

// import {
//   // userToken as currentUserToken,
//   messaging,
// } from "@/firebase/firebaseConfig";
import { OAUTH_TIMEOUT_DURATION } from "@/utils/constants";

export async function googleSignUp(data) {
  const { accessToken, deviceToken } = data;

  const config = {
    method: "post",
    url: `/auth/google/signup`,
    data: {
      accessToken: accessToken,
      deviceToken: deviceToken,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(
    config,
    OAUTH_TIMEOUT_DURATION
  );

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
export async function googleSignIn(accessToken) {
  // Get a new token before signing up
  // const localUserToken = await refreshFirebaseToken();

  // if (!localUserToken) return;

  const config = {
    method: "post",
    url: `/auth/google/login`,
    data: {
      accessToken: accessToken,
      // deviceToken: localUserToken,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(
    config,
    OAUTH_TIMEOUT_DURATION
  );

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

// export const refreshFirebaseToken = async () => {
//   try {
//     // Get the current token
//     const currentToken = await getToken(messaging, {
//       vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
//     });

//     console.log("Current Token", currentToken);

//     // Only attempt to delete the token if it exists and matches the stored token
//     if (currentToken) {
//       if (currentUserToken === currentToken) {
//         const result = await deleteToken(messaging);
//         console.log("Token deleted:", result);
//       }
//     }

//     // Request a new token after deletion
//     const newToken = await getToken(messaging, {
//       vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
//     });

//     console.log("New Token", newToken);

//     return newToken;
//   } catch (error) {
//     console.error("Error refreshing FCM token", error);
//     return null;
//   }
// };
