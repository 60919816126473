// Component imports
import Button from "@/UI/Button";

// Store Imports
import { useColumnStore } from "../column/columnStore";
import { useDomainStore } from "./domainStore";
import { usePanelStore } from "../panel/panelStore";

// API Hooks
import { addDomain, fetchDomains } from "./domainAPI";
import { fetchPanels } from "../panel/panelAPI";
import { fetchStatuses } from "../column/columnAPI";

// React imports
import { useRef, useState } from "react";

// External Libraries
import { FaXmark } from "react-icons/fa6";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Custom Hooks
import { isValidFormValue } from "@/utils/utils";
import { useClickOutside } from "@/hooks/hooks";
import { MAX_FORM_INPUT_LENGTH } from "@/utils/constants";

export default function AddDomain() {
  // Zustand States
  const {
    setDomains,
    setLoading: setDomainLoading,
    loading: DomainLoading,
    setError,
    setActiveDomain,
    domains,
  } = useDomainStore((store) => store);

  const setPanels = usePanelStore((store) => store.setPanels);

  const setColumns = useColumnStore((store) => store.setColumns);

  const setAddDomain = useDomainStore((state) => state.setAddDomain);

  // React states and refs
  const [domainName, setDomainName] = useState("");

  const containerRef = useRef(null);

  const navigate = useNavigate();

  // Function to handle adding a new domain
  async function handleAddDomain(e) {
    e.preventDefault();

    if (!isValidFormValue(domainName)) {
      toast.error("Domain name has to be longer than 2 letters");
      return;
    }

    const prevDomains = domains;

    // Optimistically update the state
    const newDomain = {
      id: Date.now(),
      name: domainName.toLowerCase(),
    };

    setDomains([...domains, newDomain]);
    setActiveDomain(newDomain); // Optimistically set the new domain as active

    setAddDomain(false);
    setDomainLoading(true);

    const data = { name: domainName.toLowerCase() };

    // Add new domain
    const addResult = await addDomain(data);

    if (!addResult.success) {
      // Revert to previous state if adding fails
      setDomains(prevDomains);
      setActiveDomain(domains[0]); // Reset to the first domain
      setError(addResult.message);
      toast.error(addResult.message);
      setDomainLoading(false);
      return;
    }

    const newDomainsResult = await fetchDomains();

    if (!newDomainsResult.success) {
      setActiveDomain(domains[0]);
      toast.error(newDomainsResult.message);
      setDomainLoading(false);
      return;
    }

    const newlyAddedDomain = newDomainsResult.data.find(
      (domain) => domain.name === newDomain.name
    );

    // Fetch panels for the newly added domain
    const [panelsResult, columnsResult] = await Promise.all([
      fetchPanels(newlyAddedDomain.id),
      fetchStatuses(newlyAddedDomain.id),
    ]);

    if (!panelsResult.success || !columnsResult.success) {
      setDomains(newDomainsResult.data);
      setActiveDomain(newDomainsResult.data[0]);
      toast.error("Failed to fetch domain details");
    }

    setPanels(panelsResult.data);
    setColumns(columnsResult.data);

    // Set the active domain to the newly added domain
    setActiveDomain(newlyAddedDomain);
    setDomains(newDomainsResult.data);

    setDomainName("");
    navigate("/home");

    setDomainLoading(false);
  }

  // Click outside handler
  useClickOutside(containerRef, () => setAddDomain(false));

  const handleChange = (e) => {
    const value = e.target.value;

    if (value.length <= MAX_FORM_INPUT_LENGTH) setDomainName(value);
  };

  return (
    <div>
      <div className="flex justify-between">
        {/* Modal Header */}
        <h2 className="mb-6 modal__header">Create New Domain</h2>
        {/* Close Modal Button */}
        <button
          className="self-start p-2 rounded-full cursor-pointer hover:bg-veryDarkGray"
          onClick={() => setAddDomain(false)}>
          <FaXmark size={20} />
        </button>
      </div>
      {/* Add Domain Form */}
      <form className="grid gap-8" onSubmit={handleAddDomain}>
        {/* Domain Name Input */}
        <div className="relative ">
          <label htmlFor="name" className="block modal__labels">
            Domain Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            value={domainName}
            placeholder=" Winterfell"
            className=" input__style__modal"
          />
          <p className="absolute top-0 text-sm font-light right-1 text-mediumGray">
            {domainName.length} / {MAX_FORM_INPUT_LENGTH}
          </p>
        </div>

        <Button content="Create New Domain" disabled={DomainLoading} />
      </form>
    </div>
  );
}

AddDomain.propTypes = {
  columns: PropTypes.array,
};
