// Component imports
import Button from "@/UI/Button";

// API Hooks
import { deletePanel, fetchPanelData } from "./panelAPI";

// External Libraries
import PropTypes from "prop-types";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

// Custom Hooks
import { useExtractPanelId } from "@/hooks/hooks";

// Store Imports
import { sanitizeTextForUrl } from "@/utils/utils";
import toast from "react-hot-toast";
import { useDomainStore } from "../domain/domainStore";
import { fetchTasks } from "../tasks/taskAPI";
import { useTaskStore } from "../tasks/tasksStore";
import { usePanelStore } from "./panelStore";

export default function DeletePanel({ close }) {
  // Zustand States
  const {
    panels,
    setPanels,
    setError: setPanelError,
    setLoading: setPanelLoading,
    setActivePanel,
  } = usePanelStore((store) => store);

  const {
    tasks,
    setTasks,
    setLoading: setTaskLoading,
    setError: setTaskError,
  } = useTaskStore((store) => store);

  const activeDomain = useDomainStore((store) => store.activeDomain);

  // Extracted panel ID from URL
  const extractedPanelId = useExtractPanelId();
  const navigate = useNavigate();

  const selectedPanel = panels.find((p) => p.id === extractedPanelId);

  const selectedPanelIndex = panels.findIndex((p) => p.id === extractedPanelId);

  const handlePanelDelete = async () => {
    // Determine the previous panel or fallback to home
    const previousPanel = panels[selectedPanelIndex - 1];

    const navigateTo = previousPanel
      ? `/panel/${sanitizeTextForUrl(previousPanel.name)}/${previousPanel.id}`
      : "/home";

    // Optimistic Delete
    const updatedPanels = panels.filter(
      (panel) => panel.id !== selectedPanel.id
    );

    setPanels(updatedPanels);
    close(false);
    navigate(navigateTo);
    setPanelLoading(true);

    console.log("1");

    setActivePanel(previousPanel ? previousPanel : null);

    const data = {
      domainID: activeDomain.id,
      panelID: selectedPanel.id,
    };

    const prevPanelAsyncData = {
      domainID: activeDomain.id,
      panelID: previousPanel.id,
    };

    const deleteResult = await deletePanel(data);

    if (!deleteResult.success) {
      toast.error(deleteResult.message);
      setPanelLoading(false);
      return;
    }

    if (tasks[previousPanel.id]) {
      setTasks(previousPanel.id, tasks[previousPanel.id]);
      setTaskLoading(false);
      setPanelLoading(false);
      return;
    }

    setTaskLoading(false);
    setPanelLoading(false);

    // Fetch tasks and panel data in parallel
    const [taskResponse, panelMembersResponse] = await Promise.all([
      fetchTasks(activeDomain.id, previousPanel.id),
      fetchPanelData(prevPanelAsyncData),
    ]);

    // Status Handler
    if (!taskResponse.success) {
      setTaskError(taskResponse.message);
      setTaskLoading(false);

      return;
    }

    // Panel Members handler
    if (!panelMembersResponse.success) {
      setPanelError(panelMembersResponse.message);
      setTaskLoading(false);
      return;
    }

    const { panel_data, panel_members } = panelMembersResponse.data;
    // Update the panels with its members
    const asyncUpdatedPanels = panels.data.map((panel) =>
      panel.id === panel_data.id
        ? { ...panel, panel_members, panel_data }
        : panel
    );

    setPanels(asyncUpdatedPanels);

    setActivePanel(
      asyncUpdatedPanels.find((p) => p.id === panel_data.id || null)
    );

    setTasks(panel_data.id, taskResponse.data);

    setTaskLoading(false);
    setPanelLoading(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h2 className="modal__header text-caution">Delete Panel ?</h2>
        <button
          onClick={() => close(false)}
          className="p-2 rounded-full cursor-pointer hover:bg-veryDarkGray">
          <FaXmark color="rgba(130, 143, 163, 1)" size={20} />
        </button>
      </div>
      <div className="  font-medium leading-[176%] mb-6">
        <p className=" text-mediumGray">
          Are you sure you want to delete the Column &quot;{" "}
          <span className="font-bold text-white">{selectedPanel.name}</span>{" "}
          &quot; Panel ? This action will remove all tasks and subtasks
        </p>
      </div>

      <div className="flex flex-col gap-4 md:flex-row">
        <Button
          variant="caution"
          content="Delete Panel"
          onClick={handlePanelDelete}
        />
        <Button variant="white" content="Cancel" onClick={() => close(false)} />
      </div>
    </div>
  );
}

DeletePanel.propTypes = {
  close: PropTypes.func,
};
