// Component imports
import DomainDropdown from "@/features/header/DomainDropdown";
import Logo from "@/UI/Logo";
import ModalWrapper from "@/UI/ModalWrapper";
import AddDomain from "../domain/AddDomain";
import HeaderHomeDropdown from "./HeaderHomeDropdown";
import HeaderPanelDropdown from "./HeaderPanelDropdown";

// External Libraries
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// React imports
import { useEffect, useState } from "react";

// Static imports
import KreedLogo from "/kreed-logo.svg";

// Store Imports
import { useDomainStore } from "../domain/domainStore";
import { FiSearch } from "react-icons/fi";

export default function Header({ setShowUtilitySearch }) {
  // Hooks and State
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const { isAddDomainOpen, setAddDomain } = useDomainStore((state) => state);

  useEffect(() => {
    const header = document.querySelector?.(".header");

    const updateHeight = () => {
      const height = header?.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );
    };

    // Set the initial height
    updateHeight();

    // Add event listener to update height on window resize
    window.addEventListener("resize", updateHeight);

    // Clean up the event listener
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // Determine if panel utils should be shown
  const showPanelUtils = location.pathname?.includes(`/panel`);
  const showSearchUtils = location.pathname?.includes(`/home`);
  const profilePage = location.pathname?.includes(`/profile`);
  const notifiationPage = location.pathname?.includes(`/notification`);

  return (
    <header
      className={`flex border-lines  justify-between sticky top-0 p-2 z-20  items-center px-3 lg:px-6 shadow-md sm:shadow-none border-r-[1px]  bg-darkGray header border-b-[1px]  sm:gap-7 gap-4 lg:gap-10 `}>
      {/* Kreed Logo (hidden on small screens) */}
      <Link to="home" className="sm:hidden">
        <img src={KreedLogo} alt="Kreed Logo" />
      </Link>

      {/* Main Logo */}
      <Logo />

      <div className="sm:ml-[50px]">
        <DomainDropdown />
      </div>

      {/* Header Dropdowns */}
      <div className="relative flex items-center justify-end flex-1 gap-3 md:gap-7">
        {!showSearchUtils && !profilePage && !notifiationPage && (
          <div
            className="absolute min-[1200px]:hidden p-2 -translate-y-1/2 rounded-full cursor-pointer top-1/2 right-12 hover:bg-veryDarkGray"
            onClick={() => setShowUtilitySearch(true)}>
            <FiSearch color="rgba(152, 170, 179, 1)" strokeWidth={2.5} />
          </div>
        )}

        {/* Dropdown for home page */}
        {!showPanelUtils && <HeaderHomeDropdown />}

        {/* Dropdown for any of the panel pages */}
        {showPanelUtils && (
          <HeaderPanelDropdown open={open} setOpen={setOpen} />
        )}
      </div>

      {/* Add Domain Component */}
      <ModalWrapper isOpen={isAddDomainOpen} onClose={setAddDomain}>
        <AddDomain />
      </ModalWrapper>
    </header>
  );
}

Header.propTypes = {
  setShowUtilitySearch: PropTypes.func.isRequired,
};
