// Component imports
import Button from "@/UI/Button";
import { OnboardingPageWrapper } from "./Onboarding";

// Static imports
import MinifiedImage from "@/assets/minified_video.svg";

// External Libraries
import PropTypes from "prop-types";

export default function OnboardingMinified({
  onBack,
  onNext,
  onboardingState,
}) {
  return (
    <OnboardingPageWrapper key={onboardingState}>
      <div className="flex flex-col items-center justify-center ">
        <div className="flex flex-col ">
          <div className="flex flex-col w-[300px]  justify-between hover:border-primary relative  cursor-pointer transition-all duration-300 pt-3 px-3 max-w-[350px] sm:max-w-full border rounded-lg min-h-[225px] border-lines gap-8">
            <h3 className="font-bold ">Watch Minified Video</h3>
            <img src={MinifiedImage} className=" w-fit" alt="" />
          </div>
          <div className="flex gap-4 mt-6">
            <Button
              variant="secondary"
              content="Back"
              className="px-8 py-2 w-max"
              onClick={onBack}
            />
            <Button
              content="Skip"
              className="px-8 py-2 rounded-full w-max"
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </OnboardingPageWrapper>
  );
}

OnboardingMinified.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onboardingState: PropTypes.number,
};
