import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function updateUser(userData, domainID) {
  const socket = getSocket();
  const config = {
    method: "patch",
    url: "/user",
    data: userData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  socket.emit("send-collab-announcement", {
    domain: domainID,
    message: "User profile modified",
  });

  return { success: true, data: responseData };
}
export async function updateUserImage(userData, domainID) {
  const socket = getSocket();
  const config = {
    method: "patch",
    url: "/user/upload",
    data: userData,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-collab-announcement", {
    domain: domainID,
    message: "User image modified",
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

// Updated getUserProfile function
export async function getUserProfile() {
  const config = {
    method: "get",
    url: "/user/profile",
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
