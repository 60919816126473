function filterData(data, fieldsToRemove) {
  return data.map((item) => {
    let filteredItem = { ...item };
    fieldsToRemove.forEach((field) => delete filteredItem[field]);
    return filteredItem;
  });
}

function ensureAssignedDates(data) {
  return data.map((item) => {
    item.assignedFrom = item.assignedFrom || "No date assigned";
    item.assignedTo = item.assignedTo || "No date assigned";
    return item;
  });
}

function ensureAssignedCollaborators(data) {
  return data.map((item) => {
    if (item.assignedCollaborators && item.assignedCollaborators.length > 0) {
      item.assignedCollaborators = item.assignedCollaborators
        .map((collaborator) => collaborator.user.fullName)
        .join(", "); // Join full names with a comma
    } else {
      item.assignedCollaborators = "No collaborators assigned"; // Set default message if no collaborators
    }
    return item;
  });
}

function extractSubtaskDetails(data) {
  // Find the maximum number of subtasks
  const maxSubtasks = Math.max(
    ...data.map((item) => item.subTasks?.length || 0)
  );

  return data.map((item) => {
    const subtasks = item.subTasks || [];
    // Add subtask details
    subtasks.forEach((subtask, index) => {
      item[`Subtask ${index + 1} Name`] = subtask.title;
      item[`Subtask ${index + 1} Status`] = subtask.done;
    });
    // Add empty placeholders for missing subtasks
    for (let i = subtasks.length; i < maxSubtasks; i++) {
      item[`Subtask ${i + 1} Name`] = "nil";
      item[`Subtask ${i + 1} Status`] = "nil";
    }
    delete item.subTasks; // Remove the original subtasks array
    return item;
  });
}

function addStatusNames(data, columns) {
  const statusMap = Object.fromEntries(
    columns.map((col) => [col.id, col.name])
  );
  return data.map((item) => {
    item["Status Name"] = statusMap[item.statusId] || "Unknown";
    delete item.statusId; // Remove the statusId after mapping
    return item;
  });
}

function convertToCSV(data) {
  const array = [Object.keys(data[0])].concat(data);

  return array
    .map((row) => {
      return Object.values(row)
        .map((value) => {
          return typeof value === "string"
            ? `"${value.replace(/"/g, '""')}"`
            : value;
        })
        .join(",");
    })
    .join("\n");
}

export function downloadCSV(data, filename, columns) {
  const fieldsToRemove = ["id", "authorId", "domainId", "panelId", "order"];
  let filteredData = filterData(data, fieldsToRemove);
  filteredData = ensureAssignedDates(filteredData); // Ensure assigned dates are set
  filteredData = extractSubtaskDetails(filteredData);
  filteredData = addStatusNames(filteredData, columns);
  filteredData = ensureAssignedCollaborators(filteredData); // Ensure assigned collaborators are set
  const csv = convertToCSV(filteredData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
