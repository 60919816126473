// Component imports
import ToggleSidebar from "@/UI/ToggleSidebar";

// External Libraries
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { GoGear } from "react-icons/go";
import { IoMdExit } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

// React imports
import { useRef, useState } from "react";

// Custom Hooks
import { useClickOutside } from "@/hooks/hooks";
import { clearBrowserStorage } from "@/utils/storeClear";

export default function SidebarUtilis({ setDynamicWidth }) {
  // State and refs
  const dropdownRef = useRef(null);
  const [showExtras, setShowExtras] = useState(false);

  useClickOutside(dropdownRef, () => setShowExtras(false));

  // Hooks and functions
  const navigate = useNavigate();

  return (
    <>
      {/* For devices with height smaller than 800px  */}
      <div className="mt-auto sidebar__extras--sm" ref={dropdownRef}>
        <div
          className="relative mt-auto mr-3 "
          onClick={() => setShowExtras(true)}>
          <div
            className={`flex w-full hover:bg-bgHover cursor-pointer  items-center gap-2 hover:text-primary py-[15px] px-3  lg:px-7 rounded-tr-3xl rounded-br-3xl transition-all duration-300 ${
              showExtras && " bg-bgHover"
            }`}>
            <GoGear />
            <span className=" small__heading text-[14px]">Extras</span>
          </div>
          <AnimatePresence>
            {showExtras && (
              <div className="absolute z-50  -bottom-[100%] -translate-y-[40%] translate-x-[100%] -right-[15%]  flex gap-2 flex-col w-max h-max">
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 100, opacity: 0 }}
                  key={showExtras}
                  className="flex flex-col h-full gap-4 p-4 border rounded-md border-lines bg-veryDarkGray">
                  <div className="rounded-md bg-darkGray">
                    <ToggleSidebar
                      setDynamicWidth={setDynamicWidth}
                      className={"rounded-md px-1"}
                    />
                  </div>
                  <div>
                    <button
                      onClick={async () => {
                        await clearBrowserStorage();
                        navigate("/auth/login");
                      }}
                      className="flex items-center w-full gap-2 px-3 py-2 text-base font-medium text-left rounded-md cursor-pointer bg-darkGray hover:bg-caution hover:text-white">
                      <IoMdExit size={17} />
                      <p>Logout</p>
                    </button>
                  </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {/* For devices with height bigger than 800px  */}
      <div className="flex flex-col gap-4 mt-auto sidebar__extras--lg">
        <div className="mr-3 transition-all duration-300 hover:bg-bgHover rounded-tr-3xl rounded-br-3xl">
          <div className="ml-0 lg:ml-4">
            <ToggleSidebar
              setDynamicWidth={setDynamicWidth}
              className={" p-1.5 mr-3 "}
            />
          </div>
        </div>
        <Link
          to={"auth/login"}
          onClick={async () => {
            await clearBrowserStorage();
            navigate("/auth/login");
          }}
          className="flex items-center transition-all duration-300 gap-2  pl-3 lg:pl-7 py-[15px] mr-3 font-bold text-left cursor-pointer px-3  lg:px-7 hover:bg-caution rounded-tr-3xl rounded-br-3xl hover:text-white ">
          <IoMdExit size={"20px"} />
          <p>Logout</p>
        </Link>
      </div>
    </>
  );
}

SidebarUtilis.propTypes = {
  setDynamicWidth: PropTypes.func,
};
