// External Libraries
import { BiError } from "react-icons/bi";
import { Link } from "react-router-dom";

// Store Imports
import { useSessionStore } from "./sessionsStore";

import { clearBrowserStorage } from "@/utils/storeClear";

export default function SessionExpire() {
  const setSessionExpired = useSessionStore((store) => store.setSessionExpired);

  return (
    <div className="flex flex-col gap-8 ">
      <div className="flex flex-col gap-4 md:flex-row">
        <div className=" min-w-[50px] w-min flex justify-center items-center aspect-square bg-[#FCF3CF]  rounded-full">
          <BiError size={30} color="#D68910" />
        </div>
        <div className="space-y-2 sm:space-y-0 ">
          <h2 className="text-lg text-white sm:text-xl">
            Your session has expired
          </h2>
          <p className=" text-mediumGray">
            Please log in again to continue using the app
          </p>
        </div>
      </div>
      <div className="flex justify-end text-xs ">
        <Link
          to="/auth/login"
          className="gap-1 px-6 py-3 font-medium rounded-full text-[15px] text-white  transition-all duration-200  border hover:bg-primaryHover border-lines"
          onClick={() => {
            setSessionExpired(false);
            clearBrowserStorage();
          }}>
          Login
        </Link>
      </div>
    </div>
  );
}
