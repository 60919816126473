import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialCollaboratorsState = {
  collaborators: [],
  loading: false,
  error: null,
};

export const useCollaboratorsStore = create(
  persist(
    (set) => ({
      ...initialCollaboratorsState,
      setCollaborators: (collaborators) => set({ collaborators }),
      setError: (error) => set({ error }),
      setLoading: (loading) => set({ loading }),
      reset: () => set(initialCollaboratorsState),
    }),
    { name: "collaborators-storage" }
  )
);
