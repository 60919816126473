import PropTypes from "prop-types";

export const InputField = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  required,
  minLength,
  onFocus,
}) => (
  <div className="relative">
    <label
      htmlFor={name}
      className="mb-2 font-normal text-[14px] text-textColor block">
      {label}
    </label>
    <input
      type={type}
      name={name}
      id={name}
      placeholder={placeholder}
      className="input__style"
      required={required}
      minLength={minLength}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
    />
  </div>
);

// Define PropTypes for the component
InputField.propTypes = {
  label: PropTypes.string.isRequired, // label is required and should be a string
  type: PropTypes.string.isRequired, // type is required and should be a string
  name: PropTypes.string.isRequired, // name is required and should be a string
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // value can be a string or number and is required
  onChange: PropTypes.func.isRequired, // onChange is required and should be a function
  placeholder: PropTypes.string, // placeholder is optional and should be a string
  required: PropTypes.bool, // required is optional and should be a boolean
  minLength: PropTypes.number, // minLength is optional and should be a number
  onFocus: PropTypes.func, // onFocus is optional and should be a function
};
