// Component imports
import OnboardingAccount from "./OnboardingAccount";
import OnboardingDocs from "./OnboardingDocs";
import OnboardingFull from "./OnboardingFull";
import OnboardingHome from "./OnboardingHome";
import OnboardingMinified from "./OnboardingMinified";
import OnboardingPreferences from "./OnboardingPreferences";
import OnboardingProfile from "./OnboardingProfile";
import OnboardingSuccess from "./OnboardingSuccess";

// External Libraries
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { FiCheck } from "react-icons/fi";

// Static imports
import KreedLogo from "/kreed-logo.svg";

// Animation configs
import { slideInVariants } from "@/utils/framerAnimationConfigs";

// Context
import { useOnboardingContext } from "./OnboardingContext";

// React imports
import { Fragment } from "react";

export default function Onboarding() {
  const {
    onboardingState,
    handleNext,
    handleBack,
    setOnboardingState,
    loading: OnboardingLoading,
  } = useOnboardingContext();

  const renderCurrentStep = () => {
    switch (onboardingState) {
      case 1:
        return <OnboardingHome onNext={handleNext} onboardingState={1} />;
      case 2:
        return (
          <OnboardingAccount
            onBack={handleBack}
            onNext={handleNext}
            onboardingState={2}
          />
        );
      case 3:
        return (
          <OnboardingPreferences
            onBack={handleBack}
            onNext={handleNext}
            onboardingState={3}
          />
        );
      case 4:
        return (
          <OnboardingProfile
            onBack={handleBack}
            onNext={handleNext}
            onboardingState={4}
          />
        );
      case 5:
        return (
          <OnboardingDocs
            onBack={handleBack}
            onNext={handleNext}
            onboardingState={5}
          />
        );
      case 6:
        return (
          <OnboardingMinified
            onBack={handleBack}
            onNext={handleNext}
            onboardingState={6}
          />
        );
      case 7:
        return (
          <OnboardingFull
            onBack={handleBack}
            onboardingState={7}
            onNext={handleNext}
          />
        );
      case 8:
        return <OnboardingSuccess onBack={handleBack} onboardingState={8} />;
      default:
        return <OnboardingHome onNext={handleNext} />;
    }
  };

  return (
    <div className="absolute top-0 left-0 z-50 flex justify-center w-full h-full px-4 py-8 overflow-y-auto text-white md:pt-16 bg-veryDarkGray">
      <div className="flex flex-col gap-12 w-full md:max-w-[80%] max-w-full lg:max-w-2xl ">
        <div className="flex justify-center gap-4 pr-7">
          <img className="w-8 aspect-square" src={KreedLogo} alt="Kreed Logo" />
          <span className="block text-2xl font-bold text-center lg:text-3xl text-textColor">
            Kreed
          </span>
        </div>
        {/* Responsive slider to mark user progress during onboarding */}
        {onboardingState > 1 && onboardingState < 8 && (
          <div className="flex items-center justify-between">
            {Array.from({ length: 4 }).map((_, index) => (
              <Fragment key={index}>
                <div
                  key={index}
                  className={`flex items-center justify-center min-w-[50px] relative circle-container rounded-full aspect-square font-bold cursor-pointer ${
                    index + 2 === onboardingState
                      ? "  bg-primary text-white"
                      : "text-mediumGray"
                  } ${
                    index + 3 <= onboardingState
                      ? "bg-primary "
                      : "bg-columnColor hover:text-white"
                  }`}
                  onClick={() => {
                    !OnboardingLoading && setOnboardingState(index + 2);
                  }}>
                  {index + 3 <= onboardingState ? (
                    <FiCheck color="#fff" size={20} />
                  ) : (
                    index + 1
                  )}
                  <svg
                    className="absolute top-0 left-0 circle-svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 150 150">
                    <circle
                      className={`circle ${
                        index + 2 === onboardingState ? "animate-circle" : ""
                      }`}
                      cx="75"
                      cy="75"
                      r="70"
                    />
                  </svg>
                </div>
                {index < 3 && (
                  <div className="relative w-full h-[2px] mx-2 bg-columnColor rounded-full">
                    <div
                      className={`absolute top-0 left-0 h-full rounded-full transition-all duration-500 ${
                        index + 3 <= onboardingState
                          ? "bg-primary"
                          : "bg-columnColor"
                      }`}
                      style={{
                        width: index + 3 <= onboardingState ? "100%" : "0%",
                      }}></div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        )}
        <AnimatePresence>{renderCurrentStep()}</AnimatePresence>
      </div>
    </div>
  );
}

export function OnboardingPageWrapper({
  children,
  onboardingState,
  className,
}) {
  return (
    <motion.div
      key={onboardingState}
      initial={slideInVariants.initial}
      animate={slideInVariants.animate}
      exit={slideInVariants.exit}
      className={className}>
      {children}
    </motion.div>
  );
}

OnboardingPageWrapper.propTypes = {
  onboardingState: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
