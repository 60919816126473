import PropTypes from "prop-types";
export default function SuccessIcon({ className }) {
  return (
    <svg
      width="92"
      height="93"
      viewBox="0 0 92 93"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="46" cy="46.5" r="38.5" fill="#38C793" />
      <circle
        cx="46"
        cy="46.5"
        r="42.1094"
        stroke="#4AB393"
        strokeOpacity="0.5"
        strokeWidth="7.21875"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0218 32.2745C58.3773 32.0606 58.8017 31.9928 59.2061 32.0853C59.6105 32.1777 59.9634 32.4231 60.1907 32.7702L61.7788 35.1924C61.9858 35.5089 62.0744 35.8883 62.0289 36.2637C61.9834 36.6391 61.8068 36.9863 61.5301 37.2442L61.5253 37.2506L61.5029 37.2714L61.4114 37.3565L61.0505 37.7014C59.0534 39.6387 57.1168 41.6373 55.2434 43.6945C51.7191 47.5702 47.5338 52.6426 44.7169 57.5642C43.9308 58.9373 42.0106 59.2325 40.8717 58.047L30.4687 47.2381C30.3196 47.0832 30.2032 46.8998 30.1263 46.699C30.0495 46.4981 30.0139 46.2839 30.0215 46.069C30.0292 45.8541 30.0799 45.6429 30.1708 45.4481C30.2616 45.2532 30.3908 45.0785 30.5505 44.9345L33.6946 42.0984C33.9709 41.8493 34.3252 41.7039 34.6969 41.6873C35.0685 41.6706 35.4344 41.7836 35.7319 42.0069L41.0401 45.9869C49.3321 37.8104 54.0339 34.6727 58.0218 32.2745Z"
        fill="white"
      />
    </svg>
  );
}

SuccessIcon.propTypes = {
  className: PropTypes.string,
};
